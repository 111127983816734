<template>
  <div class="login-view">
    <div class="left left-side-custom">
      <section class="login-button-group">
        <div class="login-required-info">
          Login to share your images
        </div>
        <div class="clearfix" />
        <div class="align-center">
          <button
            type="button"
            class="btn btn-light"
            @click="navigateTo(signInUrl)"
          >
            Sign in
          </button>
        </div>
      </section>
    </div>

    <div class="right">
      <section>
        <div class="narrow">
          <div class="shadow" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">

import { inject, onMounted, ref } from 'vue';

import { FrontendConfig } from '@/types/auth';

const signInUrl = ref('')

const getAppConfig: () => Promise<FrontendConfig> = inject('getAppConfig')!;

onMounted(async () => {
  const config = await getAppConfig()

  const redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URL!
  signInUrl.value = `https://${config.auth.baseUrl}/oauth2/authorize?client_id=${config.auth.appClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(redirectUri)}`
})

function navigateTo(href: string) {
  window.location.href = href;
}

</script>

<style scoped>

section {
  padding: 20px
}

.social-login-button {
  text-align: center;
}

.social-login-control-group {
  display: inline-block;
  width: 80%;
  max-width: 260px;
}

.login-required-info {
  font-size: x-large;
  padding-bottom: 5%;
}

.left-side-custom {
  opacity: 0.95;
}

.login-button-group {
  display: inline-block;
  top: 20%;
  height: 100%;
  position: relative;
}

.login-view {
  color: #777;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.6em;
  background-size: 100% 100%;
  background: #1778a9 url("../../public/img/background-default.jpg") fixed center center !important;
  height: 100vh
}

.clearfix {
  clear: both
}

.align-center {
  text-align: center
}

.left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  float: left;
  width: 50%;
  background: #fff;
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.08);
}

.left section {
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.left section h1 {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px
}

.right {
  float: right;
  width: 50%;
}

.right section {
}

.right .narrow {
  margin: 0 auto;
  width: 60%
}


</style>
