import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "crop-preview" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "row opacity-slider" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "label left" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "col-md-3" }
const _hoisted_10 = { class: "label right" }

import { ref } from 'vue'
import VueSlider from 'vue-slider-component'

interface Props {
  index: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CropPreviews',
  props: {
    index: {}
  },
  setup(__props: any) {



const opacity = ref(0.5)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: 'previews_'+_ctx.index,
      class: "images"
    }, [
      _createElementVNode("div", {
        id: 'previews_before_'+_ctx.index,
        class: "image-container"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        id: 'previews_after_'+_ctx.index,
        style: _normalizeStyle({ opacity: opacity.value }),
        class: "image-container overlay"
      }, null, 12, _hoisted_4)
    ], 8, _hoisted_2),
    _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, " Before (" + _toDisplayString(((1 - opacity.value) * 100).toFixed(0)) + "%) ", 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(VueSlider), {
          modelValue: opacity.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((opacity).value = $event)),
          modelModifiers: { number: true },
          min: 0,
          max: 1,
          interval: 0.1,
          tooltip: "none"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, " After (" + _toDisplayString(((opacity.value) * 100).toFixed(0)) + "%) ", 1)
      ])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1))
  ]))
}
}

})