import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 1,
  class: "share-dialog-container"
}
const _hoisted_3 = {
  key: 0,
  class: "row share-place-row"
}

import 'cropperjs/dist/cropper.css';
import { computed, onBeforeMount, ref } from 'vue';
import { decodeJwt } from 'jose'
import { useRouter } from 'vue-router';
import NavBar from '@/components/common/NavBar.vue';

import { JwtData } from '@/types/auth';
import ImageUploadStep from '@/components/share/ImageUploadStep.vue';
import CreatePointOfInterestStep from '@/components/share/CreatePointOfInterestStep.vue';
import ImageCropStep from '@/components/share/ImageCropStep.vue';

enum Step {
  Upload = 'UPLOAD',
  Crop = 'CROP',
  Map = 'MAP',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShareView',
  props: {
    index: {}
  },
  setup(__props: any) {

const router = useRouter()



const step = ref<Step>(Step.Upload)
const uploadTempIds = ref({
  before: '',
  after: ''
})
const entryId = ref('')

const displayCropperRow = computed((): Boolean => {
  return step.value == Step.Crop
})

const displayUploadRow = computed((): Boolean => {
  return step.value == Step.Upload
})

const displayMapRow = computed((): Boolean => {
  return step.value == Step.Map
})

const isVisible = (): Boolean => {
  return step.value != Step.Finished && step.value != Step.Canceled
}

const uploadComplete = (ids: any): void => {
  console.log(`Upload completed with ids ${JSON.stringify(ids)}`)
  uploadTempIds.value.after = ids.afterTempId
  uploadTempIds.value.before = ids.beforeTempId
  step.value = Step.Crop
}
const cropComplete = (cropInfo: any): void => {
  entryId.value = cropInfo.entryId
  step.value = Step.Map
}
const mapComplete = (): void => {
  step.value = Step.Finished
}

const cancel = (): void => {
  step.value = Step.Canceled
}


onBeforeMount(async () => {
  if (localStorage.token == undefined) {
    await router.push({name: 'login'})
  } else {

    // Not verifying the signature is fine here, as this only leads to a redirect for unauthenticated users.
    // Token will still be verified in the backend in next API interaction
    const token: JwtData = JSON.parse(localStorage.token);
    const tokenExpiration = decodeJwt(token.accessToken).exp!
    const now = Math.round(Date.now() / 1000)

    if (tokenExpiration < now) {
      await router.push({name: 'login'})
    }
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavBar),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (isVisible())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (displayUploadRow.value)
                    ? (_openBlock(), _createBlock(ImageUploadStep, {
                        key: 0,
                        index: 1,
                        onDone: uploadComplete,
                        onCancel: _cache[0] || (_cache[0] = ($event: any) => (cancel()))
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (displayCropperRow.value)
                    ? (_openBlock(), _createBlock(ImageCropStep, {
                        key: 0,
                        "before-temp-id": uploadTempIds.value.before,
                        "after-temp-id": uploadTempIds.value.after,
                        index: 1,
                        onDone: cropComplete,
                        onCancel: _cache[1] || (_cache[1] = ($event: any) => (cancel()))
                      }, null, 8, ["before-temp-id", "after-temp-id"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (displayMapRow.value)
                    ? (_openBlock(), _createBlock(CreatePointOfInterestStep, {
                        key: 0,
                        "entry-id": entryId.value,
                        index: 1,
                        onDone: mapComplete,
                        onCancel: _cache[2] || (_cache[2] = ($event: any) => (cancel()))
                      }, null, 8, ["entry-id"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    ])
  ], 64))
}
}

})