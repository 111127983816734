<template>
  <div class="poi-view">
    <div class="poi-title">
      <h4>{{ parseHTML(poi.title) }}</h4>
    </div>
    <hr class="no-margin">
    <div class="poi-details">
      <span class="years">
        <span
          v-if="poi !== undefined"
          class="poi-xss-overview"
        >
          <span class="glyphicon glyphicon-calendar" />
          {{ poi.beforeYear === 0 ? "unknown" : poi.beforeYear }} - {{
            poi.afterYear === 0 ? "unknown" : poi.afterYear
          }}
        </span>
      </span>
      <span class="avatar">
        <span class="poi-xss-overview">
          <span class="glyphicon glyphicon-eye-open" />
          {{ poi.numberOfViews }}
        </span>
        <span class="poi-xss-overview">
          <span v-if="!isFavorite">
            <span
              class="glyphicon"
              :class="[isHoveringStar ? 'glyphicon-star' : 'glyphicon-star-empty']"
              @click="likePoi"
              @mouseover="isHoveringStar = true"
              @mouseleave="isHoveringStar = false"
            />
          </span>
          <span v-else>
            <span
              class="glyphicon glyphicon-star"
              :class="[isHoveringStar ? 'glyphicon-star-empty' : 'glyphicon-star']"
              @click="unlikePoi"
              @mouseover="isHoveringStar = true"
              @mouseleave="isHoveringStar = false"
            />
          </span>
        </span>
        <span class="submitter-avatar-container poi-xss-overview">
          <img
            :src="'https://placehold.co/600x600.png'"
            alt=""
            class="submitter-avatar-small"
          >
        </span>
      </span>
    </div>
    <hr class="no-margin">
    <div class="poi-description">
      <div
        class="poi-description-text"
        v-html="poi.description"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'
import { Poi } from '@/types/domain';
import { AxiosInstance } from 'axios';
import { useFavoritesStore } from '@/stores/favorites'
import { useAuthStore } from '@/stores/auth';

const apiClient: AxiosInstance = inject('apiClient')!;

const favoritesStore = useFavoritesStore()
const authStore = useAuthStore()

// Props definition
interface Props {
  poi: Poi
}

const props = defineProps<Props>()

// Reactive state
const poiLikes = ref<Record<string, number>>({})
const isHoveringStar = ref(false)

// Methods
const parseHTML = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const isFavorite = computed(() => {
  return favoritesStore.isFavorite(props.poi.id)
})

const likePoi = async () => {
  // Assuming store is injected or imported
  if (authStore.user !== undefined) {
    const apiResp = await apiClient.put(
      `/v2.0/pois/${props.poi.id}/favorites`
    )
    if (apiResp.status < 300) {
      favoritesStore.addToFavorites(props.poi.id)
      if (poiLikes.value[props.poi.id] === undefined) {
        poiLikes.value[props.poi.id] = 0
      }
      poiLikes.value[props.poi.id] += 1
    }
  }
}

const unlikePoi = async () => {
  if (authStore.user !== undefined) {
    const apiResp = await apiClient.delete(
      `/v2.0/pois/${props.poi.id}/favorites`
    )
    if (apiResp.status < 300) {
      favoritesStore.removeFromFavorites(props.poi.id)
      // store.commit('removeFromFavorites', props.poi.id)

      if (poiLikes.value[props.poi.id] === undefined) {
        poiLikes.value[props.poi.id] = 0
      }
      if (poiLikes.value[props.poi.id] > 0) {
        poiLikes.value[props.poi.id] -= 1
      }
    }
  }
}

// Lifecycle hooks and watchers
onMounted(async () => {
  // Commented out as per original code
  // if (authStore.user !== undefined) {
  //   const favorites = await apiMetaHttp.get(`/users/${authStore.user.id}/favorites`)
  //   if (favorites.status < 300) {
  //     favorites.value = favorites.data
  //   }
  //
  //   const likes = await apiMetaHttp.get(`/pois/${props.poi.id}`)
  //   if (likes.status < 300 && !poiLikes.value[props.poi.id]) {
  //     poiLikes.value[props.poi.id] = likes.data.count
  //   }
  // }
})

// Watch for POI changes (commented out as per original)
// watch(() => props.poi, async () => {
//   const likes = await apiMetaHttp.get(`/pois/${props.poi.id}`)
//   if (likes.status < 300 && poiLikes.value[props.poi.id] === undefined) {
//     poiLikes.value[props.poi.id] = likes.data.count
//   }
// })
</script>

<style scoped>
.poi-view {
  padding-bottom: 20px;
}

.poi-title {
  padding: 20px;
}

.poi-description {
  text-align: justify;
  margin: 30px 30px;
}

.poi-description-text {
  overflow: scroll;
  max-height: 70vh;
}

.poi-details {
  font-size: small;
  padding: 10px 0px;
  background: #D9D9D966;
  color: #0D0D0D;
  border-top: #00000073 solid 1px;
  border-bottom: #00000057 solid 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap
}

.poi-xss-overview {
  padding-left: 20px;
}

.poi-xss-overview .glyphicon {
  padding-right: 5px;
}

.submitter-avatar-small {
  height: 15px;
  border-radius: 8px;
}

.submitter-avatar-container {
  margin-right: 10px;
}

.no-margin {
  margin: 0px
}

.poi-details .years {
  width: calc(100% / 3);
  display: inline-block;
  text-align: left;
}

.poi-details .metainfo {
  width: calc(100% / 3);
  display: inline-block;
}

.poi-details .avatar {
  width: calc(100% / 3);
  display: inline-block;
  text-align: right;
}

span > .glyphicon-star:hover {
  cursor: pointer;
}

span > .glyphicon-star-empty:hover {
  cursor: pointer;
}

.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from {
  display: none;
}
</style>