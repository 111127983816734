import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-md-12" }
const _hoisted_3 = { class: "form-row" }
const _hoisted_4 = { class: "form-group col-md-12" }
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-md-12" }
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "buttons form-group col-md-12" }
const _hoisted_9 = {
  key: 1,
  class: "btn btn-success btn-sm border-boxed button",
  disabled: ""
}
const _hoisted_10 = { class: "form-row" }
const _hoisted_11 = { class: "col-md-12" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }

import { ref, onBeforeMount } from 'vue'
//@ts-ignore
import { SmartTagz } from 'smart-tagz'
import 'smart-tagz/dist/smart-tagz.css'
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { Rekognition } from '@aws-sdk/client-rekognition'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity'
import * as buffer from 'buffer'
import axios from 'axios'
import { ProcessingResult } from '@/types/domain'
import { Option } from '@/types/common'
import { IMAGES_BASE_URL } from '@/config/urls'
import apiClient from '@/services/api/api.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'CreatePointOfInterestForm',
  props: {
    entryId: {},
    lat: {},
    lng: {}
  },
  emits: ["cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!

const defaultTags = ref<string[]>([])
let quill: Option<Quill> = undefined

const title = ref('')
const apiResponse = ref<ProcessingResult>()
const description = ref('')
const tags = ref(defaultTags.value)

onBeforeMount(async () => {
  const rekognitionClient = new Rekognition({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({
        region,
      }),
      identityPoolId: identityPoolId
    })
  })

  const imageUrl = `${IMAGES_BASE_URL}/${props.entryId}/before.jpg`
  const imageAsBuffer = await axios.get(imageUrl, {responseType: 'arraybuffer'})

  const labelsResp = await rekognitionClient.detectLabels({
    Image: {
      Bytes: buffer.Buffer.from(imageAsBuffer.data, 'utf-8'),
    },
    MinConfidence: 80
  })

  const labels = labelsResp.Labels!.map(label => label.Name!)

  defaultTags.value = labels
  tags.value = labels
})

const tagChanged = (newTags: string[]) => {
  tags.value = newTags
}

const descriptionChanged = () => {
  description.value = quill!.root.innerHTML
}

const quillReady = (q: Quill) => {
  quill = q
}

const finish = async () => {
  const poiJson = {
    title: title.value,
    descriptionRich: description.value,
    position: {
      lat: props.lat,
      lng: props.lng
    },
    tags: tags.value
  }

  const poiResponse = await apiClient.post('/api/v1.0/pois', poiJson)
  const linkResponse = await apiClient.post(`/api/v1.0/entries/${props.entryId}/pois`, {poiId: poiResponse.data.id})

  if (linkResponse.status === 200 && poiResponse.status === 201) {
    apiResponse.value = 'ok'
  } else {
    apiResponse.value = 'error'
  }
}

const cancel = () => {
  emit('cancel')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { for: "title" }, "Title", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "title",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((title).value = $event)),
          class: "form-control",
          placeholder: "A meaningful title for your place"
        }, null, 512), [
          [_vModelText, title.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "title" }, [
          _createTextVNode("Description "),
          _createElementVNode("span", { class: "optional-hint" }, "(Optional)")
        ], -1)),
        _createVNode(_unref(QuillEditor), {
          toolbar: "essential",
          placeholder: "Describe your place..",
          theme: "snow",
          onTextChange: descriptionChanged,
          onReady: quillReady
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { for: "tags" }, [
          _createTextVNode("Tag your photo "),
          _createElementVNode("span", { class: "optional-hint" }, "(Optional)")
        ], -1)),
        (_openBlock(), _createBlock(_unref(SmartTagz), {
          id: "tags",
          key: defaultTags.value.join(','),
          modelValue: description.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((description).value = $event)),
          autosuggest: "",
          editable: "",
          "input-placeholder": "Tag your images..",
          "allow-paste": {delimiter: ','},
          "allow-duplicates": false,
          "default-tags": defaultTags.value,
          "on-changed": tagChanged
        }, null, 8, ["modelValue", "default-tags"]))
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (title.value.length > 0)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "btn btn-success btn-sm border-boxed button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (finish()))
            }, " Share Images "))
          : (_openBlock(), _createElementBlock("a", _hoisted_9, " Share Images ")),
        _createElementVNode("a", {
          class: "btn btn-danger btn-sm border-boxed button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (cancel()))
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("span", null, "Cancel", -1)
        ]))
      ])
    ], 512), [
      [_vShow, apiResponse.value === undefined]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (apiResponse.value === 'ok')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[8] || (_cache[8] = [
                  _createElementVNode("div", {
                    class: "alert alert-success",
                    role: "alert"
                  }, " Your images have been shared ", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (apiResponse.value === 'error')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[9] || (_cache[9] = [
                  _createElementVNode("div", {
                    class: "alert alert-danger",
                    role: "alert"
                  }, " Something went wrong :( ", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ], 512), [
      [_vShow, apiResponse.value !== undefined]
    ])
  ], 64))
}
}

})