import { defineStore } from 'pinia'
import jwt_decode from 'jwt-decode'
import { AuthUser, JwtData } from '@/types/auth'
import { Option } from '@/types/common'

// Define the store's state interface
interface AuthState {
  user: Option<AuthUser>;
  token: Option<JwtData>;
}

// Helper function to load the initial user state from localStorage
function loadInitialUser(): Option<AuthUser> {
  const userStr = localStorage.getItem('user')
  if (!userStr) return undefined
  try {
    return JSON.parse(userStr)
  } catch {
    return undefined
  }
}

// Helper function to load the initial token state from localStorage
function loadInitialToken(): Option<JwtData> {
  const tokenStr = localStorage.getItem('token')
  if (!tokenStr) return undefined
  try {
    return JSON.parse(tokenStr)
  } catch {
    return undefined
  }
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: loadInitialUser(),
    token: loadInitialToken()
  }),

  getters: {
    userFullName(state): string {
      return `${state.user?.firstName} ${state.user?.lastName}`
    },
    isAuthenticated(state): boolean {
      return state.user !== undefined && state.token !== undefined
    },

    isAdmin(state): boolean {
      return state.user?.isAdmin ?? false
    },

    userRoles(state): string[] {
      return state.user?.roles ?? []
    },

    currentToken(state): string | undefined {
      return state.token?.idToken
    }
  },

  actions: {
    login(jwtData: JwtData): void {
      const decodedIdToken: any = jwt_decode(jwtData.idToken)

      const user: AuthUser = {
        email: decodedIdToken.email,
        picture: decodedIdToken.picture,
        firstName: decodedIdToken.given_name,
        lastName: decodedIdToken.family_name,
        isAdmin: decodedIdToken['cognito:groups']?.includes('Admin') ?? false,
        roles: decodedIdToken['cognito:groups'] ?? []
      }

      this.user = user
      this.token = jwtData

      // Persist to localStorage
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', JSON.stringify(jwtData))
      localStorage.setItem('idToken', jwtData.idToken)
    },

    logout(): void {
      this.user = undefined
      this.token = undefined

      // Clear localStorage
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('idToken')
    }
  }
})