import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { inject, onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';

import { FrontendConfig } from '@/types/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginMenu',
  setup(__props) {


const authStore = useAuthStore()
const signInUrl = ref('')

const getAppConfig: () => Promise<FrontendConfig> = inject('getAppConfig')!;

onMounted(async () => {
  const config = await getAppConfig()

  const redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URL!
  signInUrl.value = `https://${config.auth.baseUrl}/oauth2/authorize?client_id=${config.auth.appClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(redirectUri)}`
})

function navigateTo(href) {
  window.location.href = href;
}

function logout() {
  authStore.logout()
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(authStore).user !== undefined)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-primary navbar-btn",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (logout()))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "glyphicon glyphicon-log-in glyphicon-margin" }, null, -1),
          _createElementVNode("span", { class: "button-text" }, "Log out", -1)
        ])))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn-primary navbar-btn",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (navigateTo(signInUrl.value)))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "glyphicon glyphicon-log-in glyphicon-margin" }, null, -1),
          _createElementVNode("span", { class: "button-text" }, "Log in", -1)
        ])))
  ]))
}
}

})