import { defineStore } from 'pinia'
import { Poi } from '@/types/domain'
import { Option } from '@/types/common'

interface StateProps {
  selectedPoiIdx: number
  mainViewBeforeAfterReady: {
    before: boolean
    initialized: boolean
    after: boolean
  }
  pois: Poi[]
  automaticMapCenter: Option<{
    lat: number
    lng: number
  }>
}

export const usePoiStore = defineStore('pois', {
  state: (): StateProps => ({
    mainViewBeforeAfterReady: {
      before: false,
      after: false,
      initialized: false
    },
    selectedPoiIdx: 0,
    pois: [],
    automaticMapCenter: undefined,
  }),

  getters: {
    // Get current selected POI
    selectedPoi: (state): Option<Poi> =>
        state.pois[state.selectedPoiIdx],

    allPois: (state): Poi[] => state.pois,

    // Check if both before and after images are ready
    isBeforeAfterReady: (state): boolean =>
        state.mainViewBeforeAfterReady.before &&
        state.mainViewBeforeAfterReady.after,

    isSliderInitialized: (state): boolean => state.mainViewBeforeAfterReady.initialized,

    // Check if the entire view is ready (including initialization)
    isMainViewFullyReady: (state): boolean =>
        state.mainViewBeforeAfterReady.initialized &&
        state.mainViewBeforeAfterReady.before &&
        state.mainViewBeforeAfterReady.after,

    // Get loading status for specific image type
    getImageLoadingStatus: (state) => {
      return (imageType: keyof Omit<StateProps['mainViewBeforeAfterReady'], 'initialized'>): boolean =>
          state.mainViewBeforeAfterReady[imageType]
    },

    // Get total number of POIs
    totalPois: (state): number =>
        state.pois.length,

    // Check if there are any POIs available
    hasPois: (state): boolean =>
        state.pois.length > 0,

    // Get current map center coordinates
    currentMapCenter: (state): Option<{ lat: number, lng: number }> => {
      if (state.automaticMapCenter) {
        return state.automaticMapCenter
      }
      const currentPoi = state.pois[state.selectedPoiIdx]
      return currentPoi ? {
        lat: currentPoi.location.lat,
        lng: currentPoi.location.lng
      } : undefined
    },
  },
  actions: {
    selectPoi(poiIdx: number) {
      this.selectedPoiIdx = poiIdx
    },

    selectPoiByUuid(poiUuid: string): Option<Poi> {
      for (const idx in this.pois) {
        const poi = this.pois[idx]
        if (poi.id === poiUuid) {
          this.selectedPoiIdx = Number(idx)
          return poi
        }
      }

      return undefined
    },

    setPois(pois: Poi[]) {
      this.pois = pois
    },

    setMainBeforeAfterReadyStatus(payload: {
      imageType: keyof StateProps['mainViewBeforeAfterReady'],
      isReady: boolean
    }) {
      this.mainViewBeforeAfterReady[payload.imageType] = payload.isReady
    },

    setMainSliderInitState(payload: boolean) {
      this.mainViewBeforeAfterReady.initialized = payload
    },

    selectRandomPoi() {
      const poiIdx = Math.floor(Math.random() * this.pois.length)
      this.selectPoi(poiIdx)

      const poi = this.pois[poiIdx]
      if (poi !== undefined) {
        this.automaticMapCenter = {
          lat: poi.location.lat,
          lng: poi.location.lng,
        }
      }
    },
  },
})