import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "poi-view" }
const _hoisted_2 = { class: "poi-title" }
const _hoisted_3 = { class: "poi-details" }
const _hoisted_4 = { class: "years" }
const _hoisted_5 = {
  key: 0,
  class: "poi-xss-overview"
}
const _hoisted_6 = { class: "avatar" }
const _hoisted_7 = { class: "poi-xss-overview" }
const _hoisted_8 = { class: "poi-xss-overview" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "poi-description" }
const _hoisted_12 = ["innerHTML"]

import { computed, inject, onMounted, ref } from 'vue'
import { Poi } from '@/types/domain';
import { AxiosInstance } from 'axios';
import { useFavoritesStore } from '@/stores/favorites'
import { useAuthStore } from '@/stores/auth';

interface Props {
  poi: Poi
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PointOfInterestInfo',
  props: {
    poi: {}
  },
  setup(__props: any) {

const apiClient: AxiosInstance = inject('apiClient')!;

const favoritesStore = useFavoritesStore()
const authStore = useAuthStore()

// Props definition
const props = __props

// Reactive state
const poiLikes = ref<Record<string, number>>({})
const isHoveringStar = ref(false)

// Methods
const parseHTML = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const isFavorite = computed(() => {
  return favoritesStore.isFavorite(props.poi.id)
})

const likePoi = async () => {
  // Assuming store is injected or imported
  if (authStore.user !== undefined) {
    const apiResp = await apiClient.put(
      `/v2.0/pois/${props.poi.id}/favorites`
    )
    if (apiResp.status < 300) {
      favoritesStore.addToFavorites(props.poi.id)
      if (poiLikes.value[props.poi.id] === undefined) {
        poiLikes.value[props.poi.id] = 0
      }
      poiLikes.value[props.poi.id] += 1
    }
  }
}

const unlikePoi = async () => {
  if (authStore.user !== undefined) {
    const apiResp = await apiClient.delete(
      `/v2.0/pois/${props.poi.id}/favorites`
    )
    if (apiResp.status < 300) {
      favoritesStore.removeFromFavorites(props.poi.id)
      // store.commit('removeFromFavorites', props.poi.id)

      if (poiLikes.value[props.poi.id] === undefined) {
        poiLikes.value[props.poi.id] = 0
      }
      if (poiLikes.value[props.poi.id] > 0) {
        poiLikes.value[props.poi.id] -= 1
      }
    }
  }
}

// Lifecycle hooks and watchers
onMounted(async () => {
  // Commented out as per original code
  // if (authStore.user !== undefined) {
  //   const favorites = await apiMetaHttp.get(`/users/${authStore.user.id}/favorites`)
  //   if (favorites.status < 300) {
  //     favorites.value = favorites.data
  //   }
  //
  //   const likes = await apiMetaHttp.get(`/pois/${props.poi.id}`)
  //   if (likes.status < 300 && !poiLikes.value[props.poi.id]) {
  //     poiLikes.value[props.poi.id] = likes.data.count
  //   }
  // }
})

// Watch for POI changes (commented out as per original)
// watch(() => props.poi, async () => {
//   const likes = await apiMetaHttp.get(`/pois/${props.poi.id}`)
//   if (likes.status < 300 && poiLikes.value[props.poi.id] === undefined) {
//     poiLikes.value[props.poi.id] = likes.data.count
//   }
// })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(parseHTML(_ctx.poi.title)), 1)
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "no-margin" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        (_ctx.poi !== undefined)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "glyphicon glyphicon-calendar" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.poi.beforeYear === 0 ? "unknown" : _ctx.poi.beforeYear) + " - " + _toDisplayString(_ctx.poi.afterYear === 0 ? "unknown" : _ctx.poi.afterYear), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "glyphicon glyphicon-eye-open" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.poi.numberOfViews), 1)
        ]),
        _createElementVNode("span", _hoisted_8, [
          (!isFavorite.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass(["glyphicon", [isHoveringStar.value ? 'glyphicon-star' : 'glyphicon-star-empty']]),
                  onClick: likePoi,
                  onMouseover: _cache[0] || (_cache[0] = ($event: any) => (isHoveringStar.value = true)),
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (isHoveringStar.value = false))
                }, null, 34)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createElementVNode("span", {
                  class: _normalizeClass(["glyphicon glyphicon-star", [isHoveringStar.value ? 'glyphicon-star-empty' : 'glyphicon-star']]),
                  onClick: unlikePoi,
                  onMouseover: _cache[2] || (_cache[2] = ($event: any) => (isHoveringStar.value = true)),
                  onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (isHoveringStar.value = false))
                }, null, 34)
              ]))
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "submitter-avatar-container poi-xss-overview" }, [
          _createElementVNode("img", {
            src: 'https://placehold.co/600x600.png',
            alt: "",
            class: "submitter-avatar-small"
          })
        ], -1))
      ])
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "no-margin" }, null, -1)),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", {
        class: "poi-description-text",
        innerHTML: _ctx.poi.description
      }, null, 8, _hoisted_12)
    ])
  ]))
}
}

})