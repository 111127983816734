<template>
  <img
    :src="imageUrl"
    alt=""
    @load="onLoad"
  >
</template>

<script setup lang="ts">
import { computed } from 'vue'
import '../../assets/js/twentytwenty/jquery.twentytwenty.js'
import '../../assets/js/twentytwenty/jquery.event.move.js'

import { IMAGES_BASE_URL } from '@/config/urls'
import { usePoiStore } from '@/stores/pois';

interface Props {
  entryId: string
  type: 'before' | 'after'
}

const props = withDefaults(defineProps<Props>(), {})

const poiStore = usePoiStore()

const imageUrl = computed(() => {
  return `${IMAGES_BASE_URL}/${props.entryId}/${props.type}.jpg`
})

const onLoad = () => {
  poiStore.setMainBeforeAfterReadyStatus({
    imageType: props.type,
    isReady: true
  })

  if (poiStore.isBeforeAfterReady) {
    if (!poiStore.isSliderInitialized) {
      poiStore.setMainSliderInitState(true)
      // @ts-ignore
      $('#sidebar-before-after').twentytwenty({
        no_overlay: true
      })
    } else {
      $(window).trigger('resize.twentytwenty')
    }
  }
}
</script>

<style scoped>
@import '../../assets/css/twentytwenty/twentytwenty.css';
</style>
