import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "images" }
const _hoisted_2 = {
  id: "sidebar-before-after",
  class: "twentytwenty-container"
}

import BeforeAfterImage from '@/components/common/BeforeAfterImage.vue'

interface Props {
  selectedImageUuid: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BeforeAfterImagesContainer',
  props: {
    selectedImageUuid: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BeforeAfterImage, {
        "entry-id": _ctx.selectedImageUuid,
        type: "before"
      }, null, 8, ["entry-id"]),
      _createVNode(BeforeAfterImage, {
        "entry-id": _ctx.selectedImageUuid,
        type: "after"
      }, null, 8, ["entry-id"])
    ])
  ]))
}
}

})