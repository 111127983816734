import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ng-scope" }
const _hoisted_2 = { class: "tab-content" }
const _hoisted_3 = {
  id: "map",
  class: "tab-pane active"
}
const _hoisted_4 = {
  id: "users",
  class: "tab-pane"
}
const _hoisted_5 = {
  id: "general",
  class: "tab-pane"
}
const _hoisted_6 = {
  id: "reddit",
  class: "tab-pane"
}

import AdminPoiMap from '@/components/admin/AdminPoiMap.vue';
import UserManagement from '@/components/admin/UserManagement.vue';

import NavBar from '@/components/common/NavBar.vue';
import GeneralInformation from '@/components/admin/GeneralInformation.vue';
import RedditImport from '@/components/admin/RedditImport.vue';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminView',
  setup(__props) {

const router = useRouter()

onBeforeMount(async () => {
  if (!localStorage.idToken) {
    await router.push({ name: 'login' })
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavBar),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createStaticVNode("<ul id=\"images_tabs\" class=\"nav nav-tabs\"><li class=\"tab active\"><a href=\"#map\" data-toggle=\"tab\"><span class=\"nav-tab-text\">Map</span></a></li><li class=\"tab\"><a href=\"#users\" data-toggle=\"tab\"><span class=\"nav-tab-text\">Users</span></a></li><li class=\"tab\"><a href=\"#general\" data-toggle=\"tab\"><span class=\"nav-tab-text\">General</span></a></li><li class=\"tab\"><a href=\"#reddit\" data-toggle=\"tab\"><span class=\"nav-tab-text\">Reddit</span></a></li></ul>", 1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(AdminPoiMap)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(UserManagement)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(GeneralInformation, { poi: "undefined" })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(RedditImport)
        ])
      ])
    ])
  ], 64))
}
}

})