import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "config" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = { scope: "row" }
const _hoisted_6 = { class: "config" }
const _hoisted_7 = { class: "table table-striped" }
const _hoisted_8 = { scope: "row" }

import { ref, onMounted, inject, Ref } from 'vue';
import { AxiosInstance } from 'axios';
import { JSONValue } from '@/types/common';


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralInformation',
  setup(__props) {

const apiClient: AxiosInstance = inject('apiClient')!;

const frontendConfigValues = ref(
  Object.keys(process.env)
    .filter(k => k.startsWith('VUE_APP'))
    .map(k => ([k.replace('VUE_APP_', ''), process.env[k]]))
);
const backendConfigValues: Ref<JSONValue[]> = ref([]);

const populate = () => {
  console.log('populate');
};

onMounted(async () => {
  const version = await apiClient.get('/v2.0/config');
  const config = version.data
  for (const configValueName of Object.keys(config)) {
    backendConfigValues.value.push([configValueName, JSON.stringify(config[configValueName])])
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Frontend config", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(frontendConfigValues.value, (envVar) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: envVar[0]
              }, [
                _createElementVNode("th", _hoisted_5, _toDisplayString(envVar[0]), 1),
                _createElementVNode("td", null, _toDisplayString(envVar[1]), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h2", null, "API config", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(backendConfigValues.value, (envVar) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: envVar[0]
              }, [
                _createElementVNode("th", _hoisted_8, _toDisplayString(envVar[0]), 1),
                _createElementVNode("td", null, _toDisplayString(envVar[1]), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", { class: "col-md-6" }, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Management", -1)),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-default",
        onClick: populate
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("span", { class: "glyphicon glyphicon-globe glyphicon-margin" }, null, -1),
        _createTextVNode(" Populate DB ")
      ]))
    ])
  ]))
}
}

})