<template>
  <table class="table">
    <thead>
      <tr>
        <th />
        <th>Provider</th>
        <th>UserId</th>
        <th>Username</th>
        <th>Email</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Role</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="user in users"
        :key="user.id"
      >
        <td>
          <a href="/users/{{ user.id }}"><img
            class="user-thumbnail"
            :src="user.avatarUrl"
            alt="Avatar"
          ></a>
        </td>
        <td class="provider-button">
          <div
            v-if="user.provider === 'facebook'"
            class="navbar-btn btn btn-sm btn-social-icon btn-facebook"
          >
            <span class="fa fa-facebook" />
          </div>
          <div
            v-if="user.provider === 'google'"
            class="navbar-btn btn btn-sm btn-social-icon btn-google"
          >
            <span class="fa fa-google" />
          </div>
          <div
            v-if="user.provider === 'twitter'"
            class="navbar-btn-very-right btn btn-sm btn-social-icon btn-twitter"
          >
            <span class="fa fa-twitter" />
          </div>
        </td>
        <td class="user-info-text">
          {{ user.id }}
        </td>
        <td class="user-info-text">
          {{ user.username }}
        </td>
        <td class="user-info-text">
          {{ user.email }}
        </td>
        <td class="user-info-text">
          {{ user.firstName }}
        </td>
        <td class="user-info-text">
          {{ user.lastName }}
        </td>
        <td class="user-info-text">
          {{ user.role }}
        </td>
        <td class="user-management-control-elements">
          <div v-if="user.role === 'standard'">
            <div>
              <button
                type="button"
                class="btn btn-danger btn-block btn-xs"
                @click="banUser"
              >
                <span class="glyphicon glyphicon-ban-circle" />
                Ban
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success btn-xs btn-block remove-button"
                @click="promoteUserToAdmin"
              >
                <span class="glyphicon glyphicon-circle-arrow-up" />
                Promote to Admin
              </button>
            </div>
          </div>

          <div v-if="user.role === 'banned'">
            <button
              type="button"
              class="btn btn-primary btn-xs"
              @click="unbanUser"
            >
              <span class="glyphicon glyphicon-ban-circle" />
              Unban
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = inject('apiClient')!;
const users = ref([]);

const banUser = () => {
  console.log('ban');
};

const unbanUser = () => {
  console.log('unban');
};

const promoteUserToAdmin = () => {
  console.log('promote');
};

onMounted(async () => {
  // const usersResponse = await apiClient.get('/api/v1.0/users');
  // users.value = usersResponse.data;
});
</script>

<style scoped>
.user-management-control-elements {
  vertical-align: middle !important;
  text-align: left;
}

.user-management-control-elements .btn {
  margin-top: 2px;
}

.user-info-text {
  vertical-align: middle !important;
  text-align: left;
}

.user-thumbnail {
  border-radius: 6px;
  width: 50px;
}

.provider-button {
  text-align: left;
}
</style>
