<template>
  <div
    v-if="authStore.user !== undefined"
    class="dropdown"
  >
    <a
      data-toggle="dropdown"
      class="dropdown-toggle"
      href="#"
    ><img
      class="avatar-img"
      :src="authStore.user.picture"
      alt="Picture"
    ></a>
    <ul
      role="menu"
      class="dropdown-menu left loggedin"
    >
      <li class="username">
        <h5><b>{{ authStore.userFullName }}</b></h5>
      </li>
      <li class="divider reduced-top-margin" />
      <router-link
        v-slot="{ href }"
        to="/admin"
        custom
      >
        <li
          v-if="authStore.isAdmin"
          class="menu-item"
          @click="navigateTo(href)"
        >
          <span class="xs-font padded-glyphicon glyphicon glyphicon-cog" />
          Admin
        </li>
      </router-link>
      <li class="divider" />
      <li
        class="menu-item"
        @click="logout()"
      >
        <span class="xs-font padded-glyphicon glyphicon glyphicon-log-out" />
        Logout
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">

import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore()
const logout = async () => {
  authStore.logout()
}

const navigateTo = async (href: string) => {
  window.location.href = href;
}
</script>
<style scoped>

.loggedin .menu-item {
  padding-left: 10px;
}

li.menu-item:hover {
  background: #efefef;
  cursor: pointer;
}

.loggedin .username {
  padding-left: 10px;
}

.left {
  left: 8px;
}

.dropdown-menu.left {
  position: initial;
  padding: 5px !important;
  margin: 3px 0;
}

.dropdown-menu.loggedin {
  padding: 5px !important;
  margin: 8px 5px;
  min-width: 10vw;
}

.avatar-img {
  border-radius: 17px 17px 17px 17px;
  max-width: 100%;
  max-height: 100%;
  /* margin: 5px 15px; */
  margin-top: 8px;
  margin-bottom: 8px;
  height: 34px;
  opacity: 0.9;
}

.avatar-img:hover {
  opacity: 1.0;
}

.reduced-top-margin {
  margin-top: 2px !important;
}
</style>
