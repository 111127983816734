<template>
  <div class="col-md-9 map-column">
    <div
      id="mapContainer"
      class="map-container"
    />
  </div>
  <div class="col-md-3 poi-details">
    <div v-if="selectedPoi !== undefined">
      <div>
        <BeforeAfterImagesContainer :selected-image-uuid="selectedPoi.id" />
      </div>
      <span
        v-if="!deletedPois[selectedPoi.id]"
        class="poi-id-text"
      >
        {{ selectedPoi.id }}
      </span>
      <div v-if="deletedPois[selectedPoi.id]">
        <div
          class="alert alert-danger"
          role="alert"
        >
          POI deleted
        </div>
      </div>
      <PointOfInterestInfo
        v-if="!deletedPois[selectedPoi.id]"
        :poi="selectedPoi"
      />

      <div v-if="!deletedPois[selectedPoi.id]">
        <hr>
        <div class="buttons">
          <a
            v-if="!deleteConfirmation"
            class="btn btn-danger btn-sm border-boxed button"
            @click="deleteConfirmation = true"
          >
            Delete
          </a>
          <a
            v-if="deleteConfirmation"
            class="btn btn-danger btn-sm border-boxed button"
            @click="deletePoi()"
          >
            Are you sure?
          </a>
          <a
            v-if="selectedPoi.status==='standard'"
            class="btn btn-warning btn-sm border-boxed button"
            @click="disablePoi()"
          >
            Disable
          </a>
          <a
            v-if="selectedPoi.status==='disabled'"
            class="btn btn-success btn-sm border-boxed button"
            @click="enablePoi()"
          >
            <span>Enable</span>
          </a>
        </div>
      </div>
    </div>
    <div
      v-else
      class="pt-4 text-xl"
    >
      Select a marker
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, onMounted, Ref, ref, shallowRef } from 'vue';
import 'leaflet/dist/leaflet.css';
import { Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet';
import PointOfInterestInfo from '@/components/common/PointOfInterestInfo.vue';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import {
  makeDisabledMarker,
  makeRegularMarker,
  makeSelectedDisabledMarker,
  makeSelectedRegularMarker
} from '@/utils/maps';
import { useRouter } from 'vue-router';
import { Poi } from '@/types/domain';
import { Option } from '@/types/common';
import { AxiosInstance } from 'axios';
import { useAuthStore } from '@/stores/auth';

const markerIcon = makeRegularMarker();
const markerIconSelected = makeSelectedRegularMarker();
const markerIconDisabled = makeDisabledMarker();
const markerIconDisabledSelected = makeSelectedDisabledMarker();

const router = useRouter();

const authStore = useAuthStore()
const apiClient: AxiosInstance = inject('apiClient')!;

const markerClusterGroup = shallowRef(new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40
}));
const pois = ref<Poi[]>([]);
const selectedPoi = ref<Option<Poi>>(undefined);
const map = ref<Option<LeafletMap>>(undefined);
const deletedPois = ref({});
const selectedMarker = shallowRef<Option<Marker>>(undefined);
const deleteConfirmation = ref(false);
const layerOptions = {
  tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  id: 'mapbox/light-v10'
};

const deletePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }

  const poiResponse = await apiClient.delete(`/api/v1.0/pois/${selectedPoi.value.id}`);

  if (poiResponse.status < 300) {
    const index = pois.value.indexOf(selectedPoi.value);
    if (index > -1) {
      pois.value.splice(index, 1);
    }

    map.value?.removeLayer(selectedMarker.value!);

    deletedPois.value[selectedPoi.value.id] = true;
    deleteConfirmation.value = false;
  }
};

const disablePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${selectedPoi.value.id}/status`, {
    status: 'disabled'
  });

  if (poiResponse.status < 300) {
    selectedPoi.value.status = 'disabled';
    selectedMarker.value?.setIcon(markerIconDisabledSelected);
  }
};

const enablePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${selectedPoi.value.id}/status`, {
    status: 'standard'
  });

  if (poiResponse.status < 300) {
    selectedPoi.value.status = 'standard';
    selectedMarker.value?.setIcon(markerIconSelected);
  }
};

onMounted(async () => {
  const poiResponse = await apiClient.get('/v2.0/pois');

  if (poiResponse.status === 401) {
    authStore.logout()
    await router.push({name: 'login'});
  }

  pois.value = poiResponse.data.pois;
  if (map.value === undefined) {
    map.value = new LeafletMap('mapContainer').setView([48.137154, 11.576124], 5);
  }

  const tileLayer = new TileLayer(
    layerOptions.tileLayerUrl,
    {
      attribution:
        'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: layerOptions.id,
    }
  );

  map.value.addLayer(tileLayer);

  for (const poi of pois.value) {
    const marker: Marker = new Marker([poi.location.lat, poi.location.lng], {
      icon: poi.status === 'disabled' ? markerIconDisabled : markerIcon
    });

    marker.on('click', () => {
      selectedMarker.value?.setIcon(selectedPoi.value?.status === 'disabled' ? markerIconDisabled : markerIcon);
      selectedPoi.value = poi;
      selectedMarker.value = marker;
      selectedMarker.value.setIcon(selectedPoi.value.status === 'disabled' ? markerIconDisabledSelected : markerIconSelected);
      $(window).trigger('resize.twentytwenty');
    });

    markerClusterGroup.value.addLayer(marker);
  }

  map.value.addLayer(markerClusterGroup.value);
});
</script>

<style scoped>
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

.poi-details {
  overflow: scroll;
  float: right;
  text-align: center;
  box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.75);
  height: calc(100vh - 92px);
  z-index: 100;
  padding: 0px !important;
}

.map-column {
  z-index: 0;
  padding: 0px !important;
}

.map-container {
  width: 100%;
  height: calc(100vh - 94px);
}

.buttons {
  padding-top: 10px;
  float: right;
}

.button {
  margin: 5px;
}

.poi-id-text {
  font-size: x-small;
}
</style>
