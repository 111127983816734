import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-view" }
const _hoisted_2 = { class: "left left-side-custom" }
const _hoisted_3 = { class: "login-button-group" }
const _hoisted_4 = { class: "align-center" }

import { inject, onMounted, ref } from 'vue';

import { FrontendConfig } from '@/types/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {


const signInUrl = ref('')

const getAppConfig: () => Promise<FrontendConfig> = inject('getAppConfig')!;

onMounted(async () => {
  const config = await getAppConfig()

  const redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URL!
  signInUrl.value = `https://${config.auth.baseUrl}/oauth2/authorize?client_id=${config.auth.appClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(redirectUri)}`
})

function navigateTo(href: string) {
  window.location.href = href;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "login-required-info" }, " Login to share your images ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "clearfix" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-light",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (navigateTo(signInUrl.value)))
          }, " Sign in ")
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "right" }, [
      _createElementVNode("section", null, [
        _createElementVNode("div", { class: "narrow" }, [
          _createElementVNode("div", { class: "shadow" })
        ])
      ])
    ], -1))
  ]))
}
}

})