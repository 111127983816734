import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: null
});

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('idToken')

    if (token) {
      config.headers!.Authorization = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default apiClient;