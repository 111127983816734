import getAppConfig from '@/utils/config';
import axios from 'axios';
import { Option } from '@/types/common';
import { CognitoAuthResponse } from '@/types/auth';

export async function refreshAccessToken(refreshToken: string): Promise<Option<CognitoAuthResponse>> {
  const config = await getAppConfig()

  const clientId = config.auth.appClientId
  const authDomain = config.auth.baseUrl

  const login = await axios.post(`${authDomain}/oauth2/token`, new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
  }), {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    validateStatus: () => true
  })

  if (login.status >= 400) {
    return undefined
  }

  return login.data
}