<template>
  <div class="app-container">
    <PoiMap />
    <PoiDetails />
  </div>
</template>

<script setup lang="ts">
import { inject, onMounted } from 'vue';
import PoiDetails from '@/components/main/PoiDetails.vue';
import PoiMap from '@/components/main/PoiMap.vue';
import { AxiosInstance, AxiosResponse } from 'axios';

import { trySilentLogin } from '@/services/auth/jwt.service';
import { useFavoritesStore } from '@/stores/favorites';
import { usePoiStore } from '@/stores/pois';

const favoritesStore = useFavoritesStore()
const poiStore = usePoiStore();

const apiClient: AxiosInstance = inject('apiClient')!;

onMounted(async () => {
  trySilentLogin().then(async (loginSucceeded) => {
    if (loginSucceeded) {
      apiClient.get('/v2.0/users/me/favorites')
        .then((resp: AxiosResponse) => {
            if (resp.status < 300) {
              favoritesStore.setFavorites(resp.data)
            }
          }
        ).catch(() => {
          console.log('Failed to load favorites')
        }
      );
    }
  })

  apiClient.get('/v2.0/users/me/favorites')
    .then((resp: AxiosResponse) => {
        if (resp.status < 300) {
          favoritesStore.setFavorites(resp.data)
        }
      }
    ).catch(() => {
      console.log('Failed to load favorites')
    }
  );

  try {
    const poisApiResponse = await apiClient.get('/v2.0/pois');
    const pois = poisApiResponse.data.pois;

    poiStore.setPois(pois)
  } catch (error) {
    console.log('Error fetching POIs:', error);
  }
});
</script>

<style lang="sass">
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

body
  margin: 0px !important

</style>
