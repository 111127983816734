<template>
  <div class="crop-preview">
    <div
      :id="'previews_'+index"
      class="images"
    >
      <div
        :id="'previews_before_'+index"
        class="image-container"
      />
      <div
        :id="'previews_after_'+index"
        :style="{ opacity }"
        class="image-container overlay"
      />
    </div>
    <hr>
    <div class="row opacity-slider">
      <div class="col-md-3">
        <div class="label left">
          Before ({{ ((1 - opacity) * 100).toFixed(0) }}%)
        </div>
      </div>
      <div class="col-md-6">
        <vue-slider
          v-model.number="opacity"
          :min="0"
          :max="1"
          :interval="0.1"
          tooltip="none"
        />
      </div>
      <div class="col-md-3">
        <div class="label right">
          After ({{ ((opacity) * 100).toFixed(0) }}%)
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import VueSlider from 'vue-slider-component'

interface Props {
  index: number
}

defineProps<Props>()

const opacity = ref(0.5)
</script>

<style scoped>
.crop-preview > .images {
  position: relative;
  border: 2px solid #000000;
}

.crop-preview > .images > .image-container {
  overflow: hidden;
  /*width: 90%;*/
  /*height: 300px;*/
  width: calc(25vw - 30px - 4px);
  height: calc((25vw - 30px - 4px) * 3 / 4);
  top: 0;
  left: 0;
}

.crop-preview > .images > .image-container.overlay {
  overflow: hidden;
  width: calc(25vw - 30px - 4px);
  height: calc((25vw - 30px - 4px) * 3 / 4);
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.5
}

.label {
  color: black;
}

.label.left {
  text-align: left;
}

.label.right {
  text-align: right;
}
</style>
