<template>
  <div class="container-fluid">
    <div class="row headers">
      <div class="col-md-4">
        <div class="title">
          <h4>Your place in the past</h4>
        </div>
      </div>
      <div class="col-md-4">
        <div class="title">
          <h4>Your place today</h4>
        </div>
      </div>
      <div class="col-md-4">
        <div class="title">
          <h4>Preview</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <ImageCropper
          role="primary"
          :index="props.index"
          :entry-uuid="props.beforeTempId"
          :type="'before'"
          @cropboxchanged="beforeCropInfo = $event"
          @primary-cropper-changed="primaryCropperChanged"
        />
      </div>
      <div class="col-md-4">
        <ImageCropper
          ref="secondaryCropper"
          role="secondary"
          :index="props.index"
          :entry-uuid="props.afterTempId"
          :type="'after'"
          @cropboxchanged="afterCropInfo = $event"
        />
      </div>
      <div class="col-md-4 crop-preview">
        <div>
          <div class="info left-aligned">
            This is how your images will overlap after cropping. Use the croppers and slider to finetune the results.
          </div>
        </div>
        <div
          :id="'previews_'+props.index"
          class="images"
        >
          <div
            :id="'previews_before_'+props.index"
            class="image-container"
          />
          <div
            :id="'previews_after_'+props.index"
            :style="{ opacity }"
            class="image-container overlay"
          />
        </div>
        <div class="row opacity-slider">
          <div class="col-md-3">
            <div>
              Past
            </div>
          </div>
          <div class="col-md-6">
            <vue-slider
              v-model.number="opacity"
              :min="0"
              :max="1"
              :interval="0.1"
              tooltip="none"
            />
          </div>
          <div class="col-md-3">
            <div>
              Today
            </div>
          </div>
        </div>
        <hr>
        <div class="row year-selector">
          <div class="col-md-6">
            <div class="input-group">
              <label for="beforeYear">Year of the past photo <span class="optional-hint">(Optional)</span></label>
              <input
                id="beforeYear"
                v-model.number="beforeYear"
                class="form-control"
                type="number"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <label for="afterYear">Year of the recent photo <span class="optional-hint">(Optional)</span></label>
              <input
                id="afterYear"
                v-model.number="afterYear"
                class="form-control"
                type="number"
              >
            </div>
          </div>
        </div>

        <div class="buttons">
          <a
            id="next"
            data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Cropping.."
            class="btn btn-success btn-sm border-boxed button"
            @click="next()"
          >
            Continue
          </a>
          <a
            class="btn btn-danger btn-sm border-boxed button"
            @click="cancel()"
          >
            <span>Cancel</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ImageCropper from './ImageCropper.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import apiClient from '@/services/api/api.service'
import { ref } from 'vue'

const props = defineProps<{
  index: number
  beforeTempId: string
  afterTempId: string
}>()

const emit = defineEmits<{
  (e: 'done', data: any): void
  (e: 'cancel'): void
}>()

const secondaryCropper = ref()
const beforeCropInfo = ref()
const afterCropInfo = ref()
const beforeYear = ref<number | null>(null)
const afterYear = ref<number | null>(null)
const opacity = ref(0.5)

const primaryCropperChanged = (event: { width: number; height: number }) => {
  secondaryCropper.value.setAspectRatio(event.width / event.height)
}

const next = async () => {
      const body = {
        after: {
      uuid: props.afterTempId,
      year: afterYear.value,
      cropInfo: afterCropInfo.value
        },
        before: {
      uuid: props.beforeTempId,
      year: beforeYear.value,
      cropInfo: beforeCropInfo.value
        },
  }
  const submitButton = $('#next')
  submitButton.button('loading')

  const cropResponse = await apiClient.post('/api/v1.0/entries/images/crop', body)
  submitButton.button('reset')

  emit('done', cropResponse.data)
    }

const cancel = () => {
  emit('cancel')
  }
</script>

<style scoped>
.crop-preview {
  padding: 0px 13px 15px;
}

.crop-preview > .images {
  position: relative;
  border: 2px solid #000000;
}

.crop-preview > .info {
  font-size: x-small;
  text-align: left;
}

.crop-preview > .images > .image-container {
  overflow: hidden;
  width: calc(33.33333333vw - 30px);
  height: calc((33.33333333vw - 30px) * 9 / 16);
  top: 0;
  left: 0;
}

.crop-preview > .images > .image-container.overlay {
  overflow: hidden;
  width: calc(33.33333333vw - 30px);
  height: calc((33.33333333vw - 30px) * 9 / 16);
  top: 0;
  left: 0;
  position: absolute;
}

.buttons {
  padding-top: 10px;
  float: right;
}

.button {
  margin: 5px;
}

.title {
  text-align: left;
}

.opacity-slider {
  padding-top: 20px;
  padding-bottom: 0px;
}

.left-aligned {
  text-align: left;
}

.year-selector {
  text-align: left;
  padding: 20px 0px;
}

.headers {
  padding-bottom: 30px;
}

.optional-hint {
  font-size: smaller;
  color: darkgrey;
}

.info {
  padding: 0px 0px 20px 0px;
}
</style>
