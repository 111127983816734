import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }

import { inject, onMounted } from 'vue';
import PoiDetails from '@/components/main/PoiDetails.vue';
import PoiMap from '@/components/main/PoiMap.vue';
import { AxiosInstance, AxiosResponse } from 'axios';

import { trySilentLogin } from '@/services/auth/jwt.service';
import { useFavoritesStore } from '@/stores/favorites';
import { usePoiStore } from '@/stores/pois';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {

const favoritesStore = useFavoritesStore()
const poiStore = usePoiStore();

const apiClient: AxiosInstance = inject('apiClient')!;

onMounted(async () => {
  trySilentLogin().then(async (loginSucceeded) => {
    if (loginSucceeded) {
      apiClient.get('/v2.0/users/me/favorites')
        .then((resp: AxiosResponse) => {
            if (resp.status < 300) {
              favoritesStore.setFavorites(resp.data)
            }
          }
        ).catch(() => {
          console.log('Failed to load favorites')
        }
      );
    }
  })

  apiClient.get('/v2.0/users/me/favorites')
    .then((resp: AxiosResponse) => {
        if (resp.status < 300) {
          favoritesStore.setFavorites(resp.data)
        }
      }
    ).catch(() => {
      console.log('Failed to load favorites')
    }
  );

  try {
    const poisApiResponse = await apiClient.get('/v2.0/pois');
    const pois = poisApiResponse.data.pois;

    poiStore.setPois(pois)
  } catch (error) {
    console.log('Error fetching POIs:', error);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PoiMap),
    _createVNode(PoiDetails)
  ]))
}
}

})