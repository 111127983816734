import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import VueCropper from 'vue-cropperjs'
import { ref, computed, onMounted } from 'vue'
import { IMAGES_BASE_URL } from '@/config/urls'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCropper',
  props: {
    index: {},
    entryUuid: {},
    type: {},
    role: {}
  },
  emits: ["cropboxchanged", "primaryCropperChanged"],
  setup(__props: any, { emit: __emit }) {

//@ts-ignore
const props = __props

const emit = __emit

const cropper = ref()
const cropBoxData = ref()
const aspectRatio = ref(16 / 9)

const previewDiv = computed(() => {
  return `#previews_${props.type}_${props.index}`
})

const imageSource = computed(() => {
  return `${IMAGES_BASE_URL}/tmp/${props.entryUuid}.jpg`
})

onMounted(() => {
  cropBoxData.value = cropper.value.getCropBoxData()
})

const setAspectRatio = (ratio: number) => {
  const currentCropBox = cropper.value.getCropBoxData()
  const newCropBox = JSON.parse(JSON.stringify(currentCropBox))
  newCropBox.height = currentCropBox.width / ratio

  // Fix the aspect ratio to the given value
  cropper.value.setAspectRatio(ratio)
  // Resets the crop box to the last known position
  cropper.value.setCropBoxData(newCropBox)
}

const handleCrop = () => {
  if (cropper.value === null) {
    return
  }

  cropBoxData.value = cropper.value.getCropBoxData()
  const imageWidth = cropper.value.getImageData().width
  const imageHeight = cropper.value.getImageData().height
  const cropInfo = {
    left: cropper.value.getCropBoxData().left / imageWidth,
    right: (cropper.value.getCropBoxData().left + cropper.value.getCropBoxData().width) / imageWidth,
    top: cropper.value.getCropBoxData().top / imageHeight,
    bottom: (cropper.value.getCropBoxData().top + cropper.value.getCropBoxData().height) / imageHeight,
  }
  emit('cropboxchanged', cropInfo)
  if (props.role === 'primary') {
    emit('primaryCropperChanged', cropper.value.getCropBoxData())
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VueCropper), {
    ref_key: "cropper",
    ref: cropper,
    src: imageSource.value,
    alt: "Source Image",
    "initial-aspect-ratio": aspectRatio.value,
    multiple: true,
    "view-mode": 3,
    moveable: false,
    rotatable: false,
    scalable: false,
    zoomable: false,
    preview: previewDiv.value,
    "check-cross-origin": false,
    onCrop: handleCrop
  }, null, 8, ["src", "initial-aspect-ratio", "preview"]))
}
}

})