<template>
  <div>
    <button
      v-if="authStore.user !== undefined"
      class="btn btn-primary navbar-btn"
      type="button"
      @click="logout()"
    >
      <span class="glyphicon glyphicon-log-in glyphicon-margin" /><span class="button-text">Log out</span>
    </button>
    <button
      v-else
      class="btn btn-primary navbar-btn"
      type="button"
      @click="navigateTo(signInUrl)"
    >
      <span class="glyphicon glyphicon-log-in glyphicon-margin" /><span class="button-text">Log in</span>
    </button>
  </div>
</template>
<script setup lang="ts">

import { inject, onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore()
import { FrontendConfig } from '@/types/auth';

const signInUrl = ref('')

const getAppConfig: () => Promise<FrontendConfig> = inject('getAppConfig')!;

onMounted(async () => {
  const config = await getAppConfig()

  const redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URL!
  signInUrl.value = `https://${config.auth.baseUrl}/oauth2/authorize?client_id=${config.auth.appClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(redirectUri)}`
})

function navigateTo(href) {
  window.location.href = href;
}

function logout() {
  authStore.logout()
}

</script>
<style scoped>

.button-text {
  padding-left: 10px;
}

</style>
