import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { href: "/users/{{ user.id }}" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "provider-button" }
const _hoisted_5 = {
  key: 0,
  class: "navbar-btn btn btn-sm btn-social-icon btn-facebook"
}
const _hoisted_6 = {
  key: 1,
  class: "navbar-btn btn btn-sm btn-social-icon btn-google"
}
const _hoisted_7 = {
  key: 2,
  class: "navbar-btn-very-right btn btn-sm btn-social-icon btn-twitter"
}
const _hoisted_8 = { class: "user-info-text" }
const _hoisted_9 = { class: "user-info-text" }
const _hoisted_10 = { class: "user-info-text" }
const _hoisted_11 = { class: "user-info-text" }
const _hoisted_12 = { class: "user-info-text" }
const _hoisted_13 = { class: "user-info-text" }
const _hoisted_14 = { class: "user-management-control-elements" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

import { ref, onMounted, inject } from 'vue';
import { AxiosInstance } from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserManagement',
  setup(__props) {

const apiClient: AxiosInstance = inject('apiClient')!;
const users = ref([]);

const banUser = () => {
  console.log('ban');
};

const unbanUser = () => {
  console.log('unban');
};

const promoteUserToAdmin = () => {
  console.log('promote');
};

onMounted(async () => {
  // const usersResponse = await apiClient.get('/api/v1.0/users');
  // users.value = usersResponse.data;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th"),
        _createElementVNode("th", null, "Provider"),
        _createElementVNode("th", null, "UserId"),
        _createElementVNode("th", null, "Username"),
        _createElementVNode("th", null, "Email"),
        _createElementVNode("th", null, "First Name"),
        _createElementVNode("th", null, "Last Name"),
        _createElementVNode("th", null, "Role"),
        _createElementVNode("th")
      ])
    ], -1)),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: user.id
        }, [
          _createElementVNode("td", null, [
            _createElementVNode("a", _hoisted_2, [
              _createElementVNode("img", {
                class: "user-thumbnail",
                src: user.avatarUrl,
                alt: "Avatar"
              }, null, 8, _hoisted_3)
            ])
          ]),
          _createElementVNode("td", _hoisted_4, [
            (user.provider === 'facebook')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
                  _createElementVNode("span", { class: "fa fa-facebook" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (user.provider === 'google')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "fa fa-google" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (user.provider === 'twitter')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "fa fa-twitter" }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("td", _hoisted_8, _toDisplayString(user.id), 1),
          _createElementVNode("td", _hoisted_9, _toDisplayString(user.username), 1),
          _createElementVNode("td", _hoisted_10, _toDisplayString(user.email), 1),
          _createElementVNode("td", _hoisted_11, _toDisplayString(user.firstName), 1),
          _createElementVNode("td", _hoisted_12, _toDisplayString(user.lastName), 1),
          _createElementVNode("td", _hoisted_13, _toDisplayString(user.role), 1),
          _createElementVNode("td", _hoisted_14, [
            (user.role === 'standard')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-danger btn-block btn-xs",
                      onClick: banUser
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("span", { class: "glyphicon glyphicon-ban-circle" }, null, -1),
                      _createTextVNode(" Ban ")
                    ]))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-success btn-xs btn-block remove-button",
                      onClick: promoteUserToAdmin
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "glyphicon glyphicon-circle-arrow-up" }, null, -1),
                      _createTextVNode(" Promote to Admin ")
                    ]))
                  ])
                ]))
              : _createCommentVNode("", true),
            (user.role === 'banned')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-primary btn-xs",
                    onClick: unbanUser
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("span", { class: "glyphicon glyphicon-ban-circle" }, null, -1),
                    _createTextVNode(" Unban ")
                  ]))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})