import { inject } from 'vue';
import { AxiosInstance } from 'axios';

import { FrontendConfig } from '@/types/auth';

const apiClient: AxiosInstance = inject('apiClient')!;
const MAX_CONFIG_AGE = 1000 * 60 * 10 // 10min

async function getAppConfig(): Promise<FrontendConfig> {
  const storedConfig = localStorage.getItem('config')

  if (!storedConfig || Date.now() - JSON.parse(storedConfig).timestamp > MAX_CONFIG_AGE ) {
    console.log('Retrieving up-to-date config')
    const config = await apiClient.get('/v2.0/config')
    config.data.timestamp = Date.now();

    localStorage.setItem('config', JSON.stringify(config.data))

    return JSON.parse(localStorage.getItem('config')!)
  } else {
    return JSON.parse(storedConfig)
  }
}

export default getAppConfig;