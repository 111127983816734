<template>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="title">Title</label>
      <input
        id="title"
        v-model="title"
        class="form-control"
        placeholder="A meaningful title for your place"
      >
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="title">Description <span class="optional-hint">(Optional)</span></label>
      <quill-editor
        toolbar="essential"
        placeholder="Describe your place.."
        theme="snow"
        @text-change="descriptionChanged"
        @ready="quillReady"
      />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="tags">Tag your photo <span class="optional-hint">(Optional)</span></label>
      <smart-tagz
        id="tags"
        :key="defaultTags.join(',')"
        v-model="description"
        autosuggest
        editable
        input-placeholder="Tag your images.."
        :allow-paste="{delimiter: ','}"
        :allow-duplicates="false"
        :default-tags="defaultTags"
        :on-changed="tagChanged"
      />
    </div>
  </div>
  <div
    v-show="apiResponse === undefined"
    class="form-row"
  >
    <div class="buttons form-group col-md-12">
      <a
        v-if="title.length > 0"
        class="btn btn-success btn-sm border-boxed button"
        @click="finish()"
      >
        Share Images
      </a>
      <a
        v-else
        class="btn btn-success btn-sm border-boxed button"
        disabled
      >
        Share Images
      </a>
      <a
        class="btn btn-danger btn-sm border-boxed button"
        @click="cancel()"
      >
        <span>Cancel</span>
      </a>
    </div>
  </div>
  <div
    v-show="apiResponse !== undefined"
    class="form-row"
  >
    <div class="col-md-12">
      <transition name="fade">
        <div v-if="apiResponse === 'ok'">
          <div
            class="alert alert-success"
            role="alert"
          >
            Your images have been shared
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="apiResponse === 'error'">
          <div
            class="alert alert-danger"
            role="alert"
          >
            Something went wrong :(
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
//@ts-ignore
import { SmartTagz } from 'smart-tagz'
import 'smart-tagz/dist/smart-tagz.css'
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { Rekognition } from '@aws-sdk/client-rekognition'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity'
import * as buffer from 'buffer'
import axios from 'axios'
import { ProcessingResult } from '@/types/domain'
import { Option } from '@/types/common'
import { IMAGES_BASE_URL } from '@/config/urls'
import apiClient from '@/services/api/api.service'

const props = defineProps<{
  entryId: string
  lat: number
  lng: number
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!

const defaultTags = ref<string[]>([])
let quill: Option<Quill> = undefined

const title = ref('')
const apiResponse = ref<ProcessingResult>()
const description = ref('')
const tags = ref(defaultTags.value)

onBeforeMount(async () => {
  const rekognitionClient = new Rekognition({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({
        region,
      }),
      identityPoolId: identityPoolId
    })
  })

  const imageUrl = `${IMAGES_BASE_URL}/${props.entryId}/before.jpg`
  const imageAsBuffer = await axios.get(imageUrl, {responseType: 'arraybuffer'})

  const labelsResp = await rekognitionClient.detectLabels({
    Image: {
      Bytes: buffer.Buffer.from(imageAsBuffer.data, 'utf-8'),
    },
    MinConfidence: 80
  })

  const labels = labelsResp.Labels!.map(label => label.Name!)

  defaultTags.value = labels
  tags.value = labels
})

const tagChanged = (newTags: string[]) => {
  tags.value = newTags
}

const descriptionChanged = () => {
  description.value = quill!.root.innerHTML
}

const quillReady = (q: Quill) => {
  quill = q
}

const finish = async () => {
  const poiJson = {
    title: title.value,
    descriptionRich: description.value,
    position: {
      lat: props.lat,
      lng: props.lng
    },
    tags: tags.value
  }

  const poiResponse = await apiClient.post('/api/v1.0/pois', poiJson)
  const linkResponse = await apiClient.post(`/api/v1.0/entries/${props.entryId}/pois`, {poiId: poiResponse.data.id})

  if (linkResponse.status === 200 && poiResponse.status === 201) {
    apiResponse.value = 'ok'
  } else {
    apiResponse.value = 'error'
  }
}

const cancel = () => {
  emit('cancel')
}
</script>

<style scoped>
.buttons {
  padding-top: 10px;
  float: right;
}

.button {
  margin: 5px;
}

.optional-hint {
  font-size: smaller;
  color: darkgrey;
}
</style>
