import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "searchbar" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = { class: "input-group-btn" }
const _hoisted_4 = {
  key: 0,
  class: "list-group geocode-results"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "item-description" }

import { onMounted, ref, watch } from 'vue'
import { Location } from '@aws-sdk/client-location'
import 'leaflet.markercluster'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity'
import { LatLng, LeafletMouseEvent, Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet'
import { makeGeocodedMarker, makeIconCreateFunction, makeNewPoiMarker, makeRegularMarker } from '@/utils/maps'
import { Poi, SimpleLatLng } from '@/types/domain';
import { Option } from '@/types/common';

interface Props {
  initialGuess?: Option<SimpleLatLng>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapSearch',
  props: {
    initialGuess: {}
  },
  emits: ['markerselected', 'locationchanged'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const markerIcon = makeRegularMarker()
const geocodedMarkerIcon = makeGeocodedMarker()
const newMarkerIcon = makeNewPoiMarker()
const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!
const locationServiceIndexName = process.env.VUE_APP_AWS_LOCATION_SERVICE_INDEX_NAME!

const newMarker = ref<Option<Marker<any>>>()
const suggestedLocations = ref<any[]>([])
const suggestedLocationIdx = ref(-1)
const suggestedLocationMarkers = ref(new Map())
const locationClient = ref<Option<Location>>()
const location = ref<string>()
const map = ref<Option<LeafletMap>>()
const selectedExistingMarker = ref<Option<Marker<any>>>()
const initialGuessMarker = ref<Option<Marker<any>>>()
const selectedPoi = ref<Option<Poi>>()

const suggestedPoisMarkerClustererGroup = new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40,
  iconCreateFunction: makeIconCreateFunction('marker-cluster new-pois')
})
const existingPoisMarkerClustererGroup = new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40,
  iconCreateFunction: makeIconCreateFunction('marker-cluster')
})

const layerOptions = {
  tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  accessToken: process.env.VUE_APP_MAPBOX_API_KEY!,
  id: 'mapbox/light-v10',
}

const selectRecommendedMarker = async (location: any, idx: number) => {
  map.value!.setView(new LatLng(location.lat, location.lng))
  suggestedLocationIdx.value = idx
  suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))

  suggestedLocationMarkers.value.get(`${idx}`).setIcon(newMarkerIcon)
  if (newMarker.value) {
    map.value!.removeLayer(newMarker.value)
  }

  initialGuessMarker.value?.setIcon(markerIcon)
  emit('locationchanged', location)
}

const geocode = async () => {
  const geocodeApiResponse = await locationClient.value!.searchPlaceIndexForText({
    IndexName: locationServiceIndexName,
    Text: location.value
  })

  const deduplicationAccuracy = 4
  const deduplicatedResults = [
    ...new Map(geocodeApiResponse.Results!.map(res => [
      `${res?.Place?.Geometry?.Point![0].toFixed(deduplicationAccuracy)}_${res?.Place?.Geometry?.Point![1].toFixed(deduplicationAccuracy)}`,
      res
    ])).values()
  ]

  suggestedLocations.value = deduplicatedResults.map(res => ({
    lat: res?.Place?.Geometry?.Point![1],
    lng: res?.Place?.Geometry?.Point![0],
    label: res?.Place?.Label
  }))
}

watch(suggestedLocations, (newVal) => {
  map.value?.addLayer(suggestedPoisMarkerClustererGroup)
  suggestedLocationMarkers.value.clear()
  suggestedPoisMarkerClustererGroup.clearLayers()

  for (const locIdx in newVal) {
    const suggestLocation = suggestedLocations.value[locIdx]
    const marker = new Marker(
      new LatLng(suggestLocation.lat, suggestLocation.lng),
      {icon: geocodedMarkerIcon}
    )

    suggestedPoisMarkerClustererGroup.addLayer(marker)
    suggestedLocationMarkers.value.set(locIdx, marker)

    marker.on('click', () => {
      suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))
      suggestedLocationIdx.value = parseInt(locIdx)
      selectedPoi.value = undefined

      if (newMarker.value) {
        map.value?.removeLayer(newMarker.value)
      }

      marker.setIcon(newMarkerIcon)
      selectedExistingMarker.value?.setIcon(markerIcon)
      initialGuessMarker.value?.setIcon(markerIcon)

      emit('locationchanged', suggestLocation)
    })
  }

  map.value?.addLayer(suggestedPoisMarkerClustererGroup)
})

onMounted(async () => {
  locationClient.value = new Location({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({region}),
      identityPoolId: identityPoolId
    })
  })

  map.value = new LeafletMap('map-container', {zoomControl: false}).setView([48.137154, 11.576124], 6)
  map.value.doubleClickZoom.disable()

  const tileLayer = new TileLayer(
    layerOptions.tileLayerUrl,
    {
      attribution: '',
      maxZoom: 18,
      id: layerOptions.id,
      accessToken: layerOptions.accessToken,
    }
  )

  map.value.addLayer(tileLayer)
  map.value.addLayer(existingPoisMarkerClustererGroup)

  if (navigator.geolocation && !props.initialGuess) {
    navigator.geolocation.getCurrentPosition((position) => {
      map.value?.setView(new LatLng(position.coords.latitude, position.coords.longitude), 3)
    })
  }

  if (props.initialGuess) {
    const initialGuessGeoLocation = new LatLng(props.initialGuess.lat, props.initialGuess.lng)
    initialGuessMarker.value = new Marker(
      initialGuessGeoLocation,
      {icon: newMarkerIcon}
    )

    map.value.addLayer(initialGuessMarker.value)
    map.value.setView(initialGuessGeoLocation, 3)

    initialGuessMarker.value.on('click', () => {
      suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))
      suggestedLocationIdx.value = -1

      if (newMarker.value) {
        map.value?.removeLayer(newMarker.value)
      }

      selectedExistingMarker.value?.setIcon(markerIcon)
      initialGuessMarker.value?.setIcon(newMarkerIcon)

      emit('locationchanged', props.initialGuess)
    })
  }

  map.value.on('dblclick', (event: LeafletMouseEvent) => {
    selectedPoi.value = undefined
    suggestedLocationIdx.value = -1

    suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))

    if (newMarker.value) {
      map.value?.removeLayer(newMarker.value)
    }
    newMarker.value = new Marker(event.latlng, {icon: newMarkerIcon})

    map.value?.addLayer(newMarker.value)

    selectedExistingMarker.value?.setIcon(markerIcon)
    initialGuessMarker.value?.setIcon(markerIcon)

    emit('locationchanged', event.latlng)
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createStaticVNode("<div id=\"map-container\" class=\"map-container\" data-v-4ccbb30c></div><div class=\"marker-explanation-row\" data-v-4ccbb30c><span class=\"marker-explanation\" data-v-4ccbb30c><img height=\"15\" src=\"/img/markers/new_poi.png\" alt=\"\" data-v-4ccbb30c> Current Selection</span><span class=\"marker-explanation\" data-v-4ccbb30c><img height=\"15\" src=\"/img/markers/photo.png\" alt=\"\" data-v-4ccbb30c> Initial Guess</span><span class=\"marker-explanation\" data-v-4ccbb30c><img height=\"15\" src=\"/img/markers/photo_geocoded_blue.png\" alt=\"\" data-v-4ccbb30c> Search Result</span></div>", 2)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((location).value = $event)),
          class: "form-control",
          placeholder: "Search location",
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (geocode()), ["enter"]))
        }, null, 544), [
          [_vModelText, location.value]
        ]),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (geocode()))
          }, "Search")
        ])
      ])
    ]),
    (suggestedLocations.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(suggestedLocations.value, (suggestedLocation, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: suggestedLocation.label,
              type: "button",
              class: _normalizeClass(["list-group-item", { active: index == suggestedLocationIdx.value }]),
              onClick: ($event: any) => (selectRecommendedMarker(suggestedLocation, index))
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("span", null, [
                _createElementVNode("img", {
                  class: "marker-container",
                  src: "/img/markers/photo_geocoded_blue.png",
                  alt: ""
                })
              ], -1)),
              _createElementVNode("span", _hoisted_6, _toDisplayString(suggestedLocation.label), 1)
            ], 10, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})