<template>
  <nav
    role="navigation"
    class="navbar navbar-default no-bottom-margin"
  >
    <div class="container-fluid">
      <div v-if="isLoggedIn">
        <ul class="nav navbar-nav">
          <router-link
            v-slot="{ href }"
            to="/"
            custom
          >
            <li class="navbar-item">
              <a
                ref="#"
                @click="navigateTo(href)"
              >Explore</a>
            </li>
          </router-link>
          <router-link
            v-slot="{ href }"
            to="/share"
            custom
          >
            <li class="navbar-item">
              <a
                ref="#"
                @click="navigateTo(href)"
              ><span class="selected">Share</span></a>
            </li>
          </router-link>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li class="avatar-div avatar-non-transparent">
            <img
              class="avatar-img"
              :src="user?.avatarUrl"
              alt=""
            >
          </li>
          <li>
            <p class="navbar-text">
              {{ user?.fullName }}
            </p>
          </li>
          <li
            role="separator"
            class="divider"
          />
          <li>
            <p
              class="navbar-text"
              @click="logout"
            >
              <span class="glyphicon glyphicon-log-out" />
            </p>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="navbar-distance" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

const router = useRouter()

const authStore = useAuthStore()

interface User {
  avatarUrl: string
  fullName: string
  role: string
}

const getUser = (): User | undefined => {
  const userString = localStorage.getItem('user')
  return userString ? JSON.parse(userString) : undefined
}

const user = computed(() => getUser())
const isLoggedIn = computed(() => user.value !== undefined)

const logout = () => {
  authStore.logout()
  router.push({ name: 'landing' })
}

const navigateTo = (href: string) => {
  window.location.href = href
}
</script>

<style scoped>
.avatar-div {
  height: 50px;
  z-index: 1000
}

.avatar-img {
  border-radius: 17px 17px 17px 17px;
  max-width: 100%;
  max-height: 100%;
  height: 34px;
  opacity: 0.8;
  margin: 8px 5px;
}

.avatar-img:hover {
  opacity: 1.0;
}

.no-bottom-margin {
  margin-bottom: 0px !important
}

.navbar-item:hover {
  background: #efefef;
  cursor: pointer;
}

.glyphicon-log-out:hover {
  cursor: pointer;
  color: #000000;
}

.navbar-item .selected {
  font-weight: bold;
}
</style>