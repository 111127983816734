import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "input-group freeform" }
const _hoisted_6 = { class: "input-group-btn" }
const _hoisted_7 = {
  key: 1,
  class: "btn btn-success",
  disabled: "",
  type: "button"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-md-3" }
const _hoisted_10 = { class: "reddit-refresh" }
const _hoisted_11 = { class: "input-group freeform" }
const _hoisted_12 = { class: "input-group-btn" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-md-12" }
const _hoisted_15 = { class: "config" }
const _hoisted_16 = { class: "table table-striped" }
const _hoisted_17 = { scope: "row" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "preview-cell" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "score-cell" }
const _hoisted_22 = { class: "title-cell" }
const _hoisted_23 = { class: "button-cell" }
const _hoisted_24 = { class: "button-in-cell" }
const _hoisted_25 = ["id", "onClick"]
const _hoisted_26 = {
  key: 0,
  class: "button-in-cell"
}
const _hoisted_27 = ["onClick"]
const _hoisted_28 = {
  key: 1,
  class: "button-in-cell"
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 1,
  class: "row ui"
}
const _hoisted_31 = { class: "col-md-3" }
const _hoisted_32 = { class: "col-md-3" }
const _hoisted_33 = { class: "col-md-3" }
const _hoisted_34 = { class: "col-md-3" }
const _hoisted_35 = { class: "info" }
const _hoisted_36 = { class: "input-group input-group-lg full-width" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-md-6" }
const _hoisted_39 = { class: "input-group" }
const _hoisted_40 = { class: "col-md-6" }
const _hoisted_41 = { class: "input-group" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col-md-12" }
const _hoisted_44 = { class: "tags-container" }
const _hoisted_45 = { class: "row" }
const _hoisted_46 = { class: "col-md-4" }
const _hoisted_47 = {
  key: 0,
  class: "small-font"
}
const _hoisted_48 = {
  key: 1,
  class: "small-font"
}
const _hoisted_49 = { class: "col-md-4" }
const _hoisted_50 = {
  key: 0,
  class: "small-font"
}
const _hoisted_51 = {
  key: 1,
  class: "small-font"
}
const _hoisted_52 = { class: "col-md-4" }
const _hoisted_53 = {
  key: 0,
  class: "small-font"
}
const _hoisted_54 = {
  key: 1,
  class: "small-font"
}
const _hoisted_55 = { class: "row" }
const _hoisted_56 = { class: "col-md-12" }
const _hoisted_57 = { class: "buttons" }

import { ref, reactive, computed, onMounted, inject } from 'vue';
import ImageCropper from '@/components/share/ImageCropper.vue';
import { SmartTagz } from 'smart-tagz';
import { QuillEditor } from '@vueup/vue-quill';
import type { Quill } from '@vueup/vue-quill';
import { Location } from '@aws-sdk/client-location';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import CropPreviews from '@/components/common/CropPreviews.vue';
import MapSearch from '@/components/common/MapSearch.vue';
import type { ProcessingResult } from '@/types/domain';
import type { Option } from '@/types/common';
import { AxiosInstance } from 'axios';
import { REDDIT_PREVIEW_URL}  from '@/config/urls'

import { useAuthStore } from '@/stores/auth';

const OK_STATUS = 'ok';
const ERROR_STATUS = 'error';

export default /*@__PURE__*/_defineComponent({
  __name: 'RedditImport',
  setup(__props) {

// Imports
const apiClient: AxiosInstance = inject('apiClient')!;


// Components declaration is not needed with <script setup>
// defineComponents is also not needed as it's implicit

// Constants
const region = process.env.VUE_APP_AWS_REGION!;
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!;
const defaultTags = ['reddit'];

// State management
const locationClient = ref<Option<Location>>(undefined);
const hideButtons = reactive<Record<string, boolean>>({});
const redditPosts = ref<any[]>([]);
const redditScoreThreshold = ref(2000);
const tags = ref(defaultTags);
const input = ref('');
const redditPostId = ref<string | null>(null);
const redditImageData = ref<any | null>(null);
const index = ref(0);
const quill = ref<Option<Quill>>(undefined);
const secondaryCropper = ref(); // Reference to the secondary cropper component

const authStore = useAuthStore()

// Complex state objects
const poiData = reactive({
  title: '',
  description: '',
  tags: [...defaultTags],
  location: undefined as Option<any>
});

const entryData = reactive({
  beforeYear: null as null | number,
  afterYear: null as null | number,
  beforeCropInfo: null,
  afterCropInfo: null
});

const progress = reactive({
  crop: undefined as ProcessingResult,
  poiCreate: undefined as ProcessingResult,
  share: undefined as ProcessingResult
});

const newMarker = ref();

// Computed properties
const isValidPostIdOrUrl = computed(() => {
  const urlRegex = /^https:\/\/www\.reddit\.com\/r\/([a-zA-Z]*)\/comments\/([a-z0-9]{6})/i;
  const idRegex = /^[a-z0-9]{6}$/i;
  return redditPostId.value?.match(idRegex) != null ||
    redditPostId.value?.match(urlRegex) != null;
});

// Methods
const getRedditPosts = async () => {
  const response = await apiClient.get('/v2.0/admin/reddit', {
    params: {
      score: redditScoreThreshold.value
    }
  });

  if (response.status === 401) {
    authStore.logout()
  } else {
    redditPosts.value = response.data;
    redditPosts.value.sort((p1, p2) => p2.score - p1.score);
  }
};

const hideRedditPost = (postId: string) => {
  const idx = redditPosts.value.findIndex((post) => post.id === postId);
  redditPosts.value.splice(idx, 1);
};

const cancel = () => {
  redditPostId.value = null;
  redditImageData.value = null;
  input.value = '';
  Object.assign(poiData, {
    title: '',
    description: '',
    tags: [...defaultTags],
    location: undefined
  });
  Object.assign(entryData, {
    beforeYear: null,
    afterYear: null,
    beforeCropInfo: null,
    afterCropInfo: null
  });
  Object.assign(progress, {
    crop: undefined,
    poiCreate: undefined,
    share: undefined
  });
  newMarker.value = undefined;
};

const cancelAndIgnore = () => {
  if (redditPostId.value) {
    ignoreRedditPost(redditPostId.value);
    hideRedditPost(redditPostId.value);
  }
  cancel();
};

const parseHTML = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const stringIndicatesTodayDate = (title: string) => {
  const indicators = ['today', 'present', 'now'];
  return indicators.some(ind => title.toLowerCase().includes(ind));
};

const makeDescriptionHtml = (postData: any, title: string) => {
  return `<p>${title}</p><p><br></p><p>Uploaded to <a href="https://redd.it/${postData.postId}" rel="noopener noreferrer" target="_blank">reddit</a> by user <a href="https://www.reddit.com/user/${postData.postAuthor}" rel="noopener noreferrer" target="_blank">${postData.postAuthor}</a></p>`;
};

const submit = async () => {
  const poiJson = {
    title: poiData.title,
    descriptionRich: poiData.description,
    position: {
      lat: poiData.location!.lat,
      lng: poiData.location!.lng
    },
    tags: poiData.tags
  };

  const entryJson = {
    after: {
      uuid: redditImageData.value?.afterId,
      year: entryData.afterYear,
      cropInfo: entryData.afterCropInfo
    },
    before: {
      uuid: redditImageData.value?.beforeId,
      year: entryData.beforeYear,
      cropInfo: entryData.beforeCropInfo
    },
  };

  const submitButton = $('#finish-poi');
  submitButton.button('loading');

  try {
    // Process crops
    const cropResponse = await apiClient.post('/api/v1.0/entries/images/crop', entryJson);
    progress.crop = cropResponse.status >= 200 && cropResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Create POI
    const poiResponse = await apiClient.post('/api/v1.0/pois', poiJson);
    progress.poiCreate = poiResponse.status >= 200 && poiResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Link entry
    const linkResponse = await apiClient.post(
      `/api/v1.0/entries/${cropResponse.data.entryId}/pois`,
      { poiId: poiResponse.data.id }
    );
    progress.share = linkResponse.status >= 200 && linkResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Update Reddit metadata
    // await apiMetaHttp.put(`/admin/reddit/${redditPostId.value}/${poiResponse.data.id}`);
    // await apiMetaHttp.put(`/admin/reddit/${redditPostId.value}`);

    if (redditPostId.value) {
      hideRedditPost(redditPostId.value);
    }
    setTimeout(cancel, 1000);
  } finally {
    submitButton.button('reset');
  }
};

const ignoreRedditPost = async (redditPostId) => {
  // const response = await this.apiMetaHttp.delete(`/admin/reddit/${redditPostId}`)
  // console.log(response)
  hideRedditPost(redditPostId)
}

const processRedditPost = async (postId: string, formInput = false) => {
  redditPostId.value = postId;
  hideButtons[postId] = true;

  const submitButton = formInput ?
    $('#get-reddit-images') :
    $(`#submit_${postId}`);

  submitButton.button('loading');

  try {
    const resp = await apiClient.get(`/api/v1.0/admin/reddit/${postId}`);
    redditImageData.value = resp.data;

    // Update POI data
    poiData.title = parseHTML(redditImageData.value.title) ?? '';
    poiData.tags = [...defaultTags, ...redditImageData.value.tags];
    poiData.location = redditImageData.value.location;
    poiData.description = makeDescriptionHtml(
      redditImageData.value,
      poiData.title
    );

    // Update entry data
    entryData.beforeYear = redditImageData.value.beforeYear;
    entryData.afterYear = redditImageData.value.afterYear === undefined &&
    stringIndicatesTodayDate(poiData.title) ?
      new Date().getFullYear() :
      redditImageData.value.afterYear;
  } finally {
    submitButton.button('reset');
    hideButtons[postId] = false;
  }
};

// Event handlers
const descriptionChanged = () => {
  if (quill.value?.root) {
    poiData.description = quill.value.root.innerHTML;
  }
};

const tagChanged = (newTags: string[]) => {
  poiData.tags = newTags;
};

const quillReady = (q: Quill) => {
  quill.value = q;
};

const primaryCropperChanged = (event: { width: number; height: number }) => {
  secondaryCropper.value?.setAspectRatio(event.width / event.height);
};

// Lifecycle hooks
onMounted(async () => {
  locationClient.value = new Location({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({ region }),
      identityPoolId
    })
  });

  await getRedditPosts();
});

// Note: With <script setup>, all variables and functions are automatically exposed to the template
// No need for a return statement

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (redditImageData.value === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "category-header" }, [
                _createElementVNode("h4", null, "Get reddit post")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _cache[15] || (_cache[15] = _createElementVNode("span", {
                  id: "reddit-id",
                  class: "input-group-addon"
                }, "Reddit source", -1)),
                _withDirectives(_createElementVNode("input", {
                  id: "title",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((input).value = $event)),
                  "aria-describedby": "reddit-id",
                  class: "form-control",
                  placeholder: "The post id or url of the reddit post",
                  onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputChanged && _ctx.inputChanged(...args)))
                }, null, 544), [
                  [_vModelText, input.value]
                ]),
                _createElementVNode("span", _hoisted_6, [
                  (redditPostId.value != null)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        id: "get-reddit-images",
                        class: "btn btn-success",
                        "data-loading-text": "<i class='fa fa-circle-o-notch fa-spin'></i> Getting images from reddit..",
                        type: "button",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (processRedditPost(redditPostId.value, true)))
                      }, "Import"))
                    : (_openBlock(), _createElementBlock("button", _hoisted_7, "Import"))
                ])
              ])
            ])
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "category-header" }, [
                _createElementVNode("h4", null, "Top reddit posts")
              ], -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[17] || (_cache[17] = _createElementVNode("span", {
                    id: "top-post-control",
                    class: "input-group-addon"
                  }, "Minimum Score", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((redditScoreThreshold).value = $event)),
                    "aria-describedby": "top-post-control",
                    class: "form-control"
                  }, null, 512), [
                    [_vModelText, redditScoreThreshold.value]
                  ]),
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("button", {
                      class: "btn btn-success",
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (getRedditPosts()))
                    }, "Reload")
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("table", _hoisted_16, [
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(redditPosts.value, (post) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: post.id
                      }, [
                        _createElementVNode("th", _hoisted_17, [
                          _createElementVNode("a", {
                            rel: "noopener noreferrer",
                            target: "_blank",
                            href: 'https://redd.it/'+post.id
                          }, _toDisplayString(post.id), 9, _hoisted_18)
                        ]),
                        _createElementVNode("td", _hoisted_19, [
                          (post.hasPreview)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "preview-image",
                                src: `${_unref(REDDIT_PREVIEW_URL)}/${post.id}.jpg`,
                                alt: ""
                              }, null, 8, _hoisted_20))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", _hoisted_21, _toDisplayString(post.score), 1),
                        _createElementVNode("td", _hoisted_22, _toDisplayString(post.title), 1),
                        _createElementVNode("td", _hoisted_23, [
                          _createElementVNode("span", _hoisted_24, [
                            _createElementVNode("button", {
                              id: 'submit_'+post.id,
                              class: "btn btn-sm btn-success",
                              "data-loading-text": "<i class='fa fa-circle-o-notch fa-spin'></i> Getting images from reddit..",
                              type: "button",
                              onClick: ($event: any) => (processRedditPost(post.id))
                            }, "Import ", 8, _hoisted_25)
                          ]),
                          (hideButtons[post.id] !== true)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                                _createElementVNode("button", {
                                  class: "btn btn-sm btn-warning",
                                  type: "button",
                                  onClick: ($event: any) => (hideRedditPost(post.id))
                                }, "Hide", 8, _hoisted_27)
                              ]))
                            : _createCommentVNode("", true),
                          (hideButtons[post.id] !== true)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                                _createElementVNode("button", {
                                  class: "btn btn-sm btn-danger",
                                  type: "button",
                                  onClick: ($event: any) => (ignoreRedditPost(post.id))
                                }, "Ignore", 8, _hoisted_29)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (redditImageData.value !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createVNode(ImageCropper, {
              "entry-uuid": redditImageData.value.beforeId,
              index: index.value,
              role: "primary",
              type: "before",
              onCropboxchanged: _cache[5] || (_cache[5] = ($event: any) => (entryData.beforeCropInfo = $event)),
              onPrimaryCropperChanged: primaryCropperChanged
            }, null, 8, ["entry-uuid", "index"])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createVNode(ImageCropper, {
              ref: "secondarycropper",
              "entry-uuid": redditImageData.value.afterId,
              index: index.value,
              role: "secondary",
              type: "after",
              onCropboxchanged: _cache[6] || (_cache[6] = ($event: any) => (entryData.afterCropInfo = $event))
            }, null, 8, ["entry-uuid", "index"])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createVNode(CropPreviews, { index: index.value }, null, 8, ["index"]),
            _createElementVNode("div", null, [
              _createVNode(MapSearch, {
                "initial-guess": redditImageData.value.location,
                onLocationchanged: _cache[7] || (_cache[7] = ($event: any) => (poiData.location = $event))
              }, null, 8, ["initial-guess"])
            ])
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "postTitle" }, "Title", -1)),
                _withDirectives(_createElementVNode("input", {
                  id: "postTitle",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((poiData.title) = $event)),
                  class: "form-control",
                  placeholder: "The title of the post",
                  type: "text"
                }, null, 512), [
                  [_vModelText, poiData.title]
                ])
              ]),
              _cache[31] || (_cache[31] = _createElementVNode("hr", null, null, -1)),
              _cache[32] || (_cache[32] = _createElementVNode("label", { for: "title" }, [
                _createTextVNode("Description "),
                _createElementVNode("span", { class: "optional-hint" }, "(Optional)")
              ], -1)),
              _createVNode(_unref(QuillEditor), {
                content: poiData.description,
                "onUpdate:content": _cache[9] || (_cache[9] = ($event: any) => ((poiData.description) = $event)),
                "content-type": "html",
                placeholder: "Describe your place..",
                theme: "snow",
                toolbar: "minimal",
                onReady: quillReady,
                onTextChange: descriptionChanged
              }, null, 8, ["content"]),
              _cache[33] || (_cache[33] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", { for: "beforeYear" }, "Before", -1)),
                    _withDirectives(_createElementVNode("input", {
                      id: "beforeYear",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((entryData.beforeYear) = $event)),
                      class: "form-control",
                      type: "number"
                    }, null, 512), [
                      [
                        _vModelText,
                        entryData.beforeYear,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", { for: "afterYear" }, "After", -1)),
                    _withDirectives(_createElementVNode("input", {
                      id: "afterYear",
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((entryData.afterYear) = $event)),
                      class: "form-control",
                      type: "number"
                    }, null, 512), [
                      [
                        _vModelText,
                        entryData.afterYear,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _createVNode(_unref(SmartTagz), {
                      "allow-duplicates": false,
                      "allow-paste": {delimiter: ','},
                      "default-tags": poiData.tags,
                      "on-changed": tagChanged,
                      autosuggest: "",
                      editable: "",
                      "input-placeholder": "Tag your images.."
                    }, null, 8, ["default-tags"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  (progress.crop === 'ok')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_47, _cache[23] || (_cache[23] = [
                        _createElementVNode("i", { class: "fa fa-check success" }, null, -1),
                        _createTextVNode(" Images")
                      ])))
                    : _createCommentVNode("", true),
                  (progress.crop === 'error')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_48, _cache[24] || (_cache[24] = [
                        _createElementVNode("i", { class: "fa fa-times error" }, null, -1),
                        _createTextVNode(" Images")
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_49, [
                  (progress.poiCreate === 'ok')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_50, _cache[25] || (_cache[25] = [
                        _createElementVNode("i", { class: "fa fa-check success" }, null, -1),
                        _createTextVNode(" Point of Interest")
                      ])))
                    : _createCommentVNode("", true),
                  (progress.poiCreate === 'error')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_51, _cache[26] || (_cache[26] = [
                        _createElementVNode("i", { class: "fa fa-times error" }, null, -1),
                        _createTextVNode(" Point of Interest")
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_52, [
                  (progress.share === 'ok')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_53, _cache[27] || (_cache[27] = [
                        _createElementVNode("i", { class: "fa fa-check success" }, null, -1),
                        _createTextVNode(" Sharing")
                      ])))
                    : _createCommentVNode("", true),
                  (progress.share === 'error')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_54, _cache[28] || (_cache[28] = [
                        _createElementVNode("i", { class: "fa fa-times error" }, null, -1),
                        _createTextVNode(" Sharing")
                      ])))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("a", {
                      id: "finish-poi",
                      class: "btn btn-success btn-sm border-boxed button",
                      "data-loading-text": "<i class='fa fa-circle-o-notch fa-spin'></i> Processing..",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (submit()))
                    }, " Create "),
                    _createElementVNode("a", {
                      class: "btn btn-danger btn-sm border-boxed button",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (cancel()))
                    }, _cache[29] || (_cache[29] = [
                      _createElementVNode("span", null, "Cancel", -1)
                    ])),
                    _createElementVNode("a", {
                      class: "btn btn-danger btn-sm border-boxed button",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (cancelAndIgnore()))
                    }, _cache[30] || (_cache[30] = [
                      _createElementVNode("span", null, "Cancel and ignore", -1)
                    ]))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})