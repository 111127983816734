import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "poi-details" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 0,
  class: "buttons"
}

import { computed, inject } from 'vue'
import PointOfInterestInfo from '../common/PointOfInterestInfo.vue'
import { AxiosInstance } from 'axios';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import { useAuthStore } from '@/stores/auth';
import { usePoiStore } from '@/stores/pois';


export default /*@__PURE__*/_defineComponent({
  __name: 'PoiDetails',
  setup(__props) {

const poiStore = usePoiStore()
const authStore = useAuthStore()
const apiClient: AxiosInstance = inject('apiClient')!

const poiListNotEmpty = computed(() => {
  return poiStore.hasPois
})

const currentPoi = computed(() => {
  return poiStore.selectedPoi!
})

const disablePoi = async () => {
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${currentPoi.value.id}/status`, {
    status: 'disabled'
  })
  if (poiResponse.status < 300) {
    currentPoi.value.status = 'disabled'
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (poiListNotEmpty.value)
      ? (_openBlock(), _createBlock(BeforeAfterImagesContainer, {
          key: 0,
          "selected-image-uuid": currentPoi.value.id
        }, null, 8, ["selected-image-uuid"]))
      : _createCommentVNode("", true),
    (poiListNotEmpty.value)
      ? (_openBlock(), _createBlock(PointOfInterestInfo, {
          key: 1,
          poi: currentPoi.value
        }, null, 8, ["poi"]))
      : _createCommentVNode("", true),
    (poiListNotEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(authStore).user?.role === 'admin')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
                (currentPoi.value.status === 'standard')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "btn btn-danger btn-sm border-boxed button",
                      onClick: disablePoi
                    }, " Disable "))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})