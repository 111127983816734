<template>
  <div>
    <div class="col-md-4">
      <BeforeAfterImagesContainer
        class="preview"
        :selected-image-uuid="entryId"
      />
    </div>
    <div class="col-md-4">
      <MapSearch @locationchanged="locationChanged" />
    </div>
    <div class="col-md-4 left-aligned-text">
      <div v-if="markerPosition === undefined">
        <div class="x-small-text poi-add-hint header-section">
          Double click on the map or search to create a new place
        </div>
      </div>
      <div v-else>
        <CreatePointOfInterestForm
          :entry-id="entryId"
          :lat="markerPosition.lat"
          :lng="markerPosition.lng"
          @cancel="cancel"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue'
import CreatePointOfInterestForm from '@/components/share/CreatePointOfInterestForm.vue'
import MapSearch from '@/components/common/MapSearch.vue'
import { SimpleLatLng } from '@/types/domain'
import { Option } from '@/types/common'

defineProps<{
  entryId: string
}>()

const emit = defineEmits<{
  (e: 'done'): void
  (e: 'cancel'): void
}>()

const markerPosition = ref<Option<SimpleLatLng>>()

const locationChanged = (data: SimpleLatLng) => {
  markerPosition.value = data
}

const cancel = () => {
  emit('cancel')
}
</script>

<style scoped>
.preview {
  border: black solid 1px;
}

.left-aligned-text {
  text-align: left;
}

.poi-add-hint {
  font-size: medium;
  text-align: center;
}

.header-section {
  padding: 0px 20px;
}
</style>
