import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-md-4" }
const _hoisted_2 = { class: "col-md-4" }
const _hoisted_3 = { class: "col-md-4 left-aligned-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

import { ref } from 'vue'
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue'
import CreatePointOfInterestForm from '@/components/share/CreatePointOfInterestForm.vue'
import MapSearch from '@/components/common/MapSearch.vue'
import { SimpleLatLng } from '@/types/domain'
import { Option } from '@/types/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'CreatePointOfInterestStep',
  props: {
    entryId: {}
  },
  emits: ["done", "cancel"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const markerPosition = ref<Option<SimpleLatLng>>()

const locationChanged = (data: SimpleLatLng) => {
  markerPosition.value = data
}

const cancel = () => {
  emit('cancel')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BeforeAfterImagesContainer, {
        class: "preview",
        "selected-image-uuid": _ctx.entryId
      }, null, 8, ["selected-image-uuid"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MapSearch, { onLocationchanged: locationChanged })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (markerPosition.value === undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "x-small-text poi-add-hint header-section" }, " Double click on the map or search to create a new place ", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(CreatePointOfInterestForm, {
              "entry-id": _ctx.entryId,
              lat: markerPosition.value.lat,
              lng: markerPosition.value.lng,
              onCancel: cancel
            }, null, 8, ["entry-id", "lat", "lng"])
          ]))
    ])
  ]))
}
}

})