import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "navigation left-side" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "navigation right-side" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }

import LoginMenu from '@/components/main/LoginMenu.vue';
import UserMenu from '@/components/main/UserMenu.vue';
import { useAuthStore } from '@/stores/auth';
import { usePoiStore } from '@/stores/pois';

export default /*@__PURE__*/_defineComponent({
  __name: 'Navigation',
  setup(__props) {

const authStore = useAuthStore();
const poiStore = usePoiStore()

const navigateTo = async (href: string) => {
  window.location.href = href;
}

const getRandomPoi = async () => {
  poiStore.selectRandomPoi()
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      false
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            custom: "",
            to: "/share"
          }, {
            default: _withCtx(({ href }) => [
              _createElementVNode("button", {
                class: "btn btn-default navbar-btn",
                type: "button",
                onClick: ($event: any) => (navigateTo(href))
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "glyphicon glyphicon-picture glyphicon-margin" }, null, -1),
                _createElementVNode("span", { class: "button-text" }, "Share images", -1)
              ]), 8, _hoisted_2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "btn btn-primary navbar-btn explore-button",
        type: "button",
        onClick: getRandomPoi
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "glyphicon glyphicon-random glyphicon-margin" }, null, -1),
        _createElementVNode("span", { class: "button-text" }, "Random place", -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_unref(authStore).user === undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(LoginMenu)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_unref(authStore).user !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(UserMenu)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})