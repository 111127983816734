<template>
  <div class="container-fluid">
    <div v-if="redditImageData === null">
      <div class="row">
        <div class="col-md-6">
          <div class="category-header">
            <h4>Get reddit post</h4>
          </div>
          <div class="input-group freeform">
            <span
              id="reddit-id"
              class="input-group-addon"
            >Reddit source</span>
            <input
              id="title"
              v-model="input"
              aria-describedby="reddit-id"
              class="form-control"
              placeholder="The post id or url of the reddit post"
              @input="inputChanged"
            >
            <span class="input-group-btn">
              <button
                v-if="redditPostId != null"
                id="get-reddit-images"
                class="btn btn-success"
                data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Getting images from reddit.."
                type="button"
                @click="processRedditPost(redditPostId, true)"
              >Import</button>
              <button
                v-else
                class="btn btn-success"
                disabled
                type="button"
              >Import</button>
            </span>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-3">
          <div class="category-header">
            <h4>Top reddit posts</h4>
          </div>
          <div class="reddit-refresh">
            <div class="input-group freeform">
              <span
                id="top-post-control"
                class="input-group-addon"
              >Minimum Score</span>
              <input
                v-model="redditScoreThreshold"
                aria-describedby="top-post-control"
                class="form-control"
              >
              <span class="input-group-btn">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="getRedditPosts()"
                >Reload</button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="config">
            <table class="table table-striped">
              <tbody>
                <tr
                  v-for="(post) in redditPosts"
                  :key="post.id"
                >
                  <th scope="row">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      :href="'https://redd.it/'+post.id"
                    >{{ post.id }}</a>
                  </th>
                  <td class="preview-cell">
                    <img
                      v-if="post.hasPreview"
                      class="preview-image"
                      :src="`${REDDIT_PREVIEW_URL}/${post.id}.jpg`"
                      alt=""
                    >
                  </td>
                  <td class="score-cell">
                    {{ post.score }}
                  </td>
                  <td class="title-cell">
                    {{ post.title }}
                  </td>
                  <td class="button-cell">
                    <span class="button-in-cell">
                      <button
                        :id="'submit_'+post.id"
                        class="btn btn-sm btn-success"
                        data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Getting images from reddit.."
                        type="button"
                        @click="processRedditPost(post.id)"
                      >Import
                      </button>
                    </span>
                    <span
                      v-if="hideButtons[post.id] !== true"
                      class="button-in-cell"
                    >
                      <button
                        class="btn btn-sm btn-warning"
                        type="button"
                        @click="hideRedditPost(post.id)"
                      >Hide</button>
                    </span>
                    <span
                      v-if="hideButtons[post.id] !== true"
                      class="button-in-cell"
                    >
                      <button
                        class="btn btn-sm btn-danger"
                        type="button"
                        @click="ignoreRedditPost(post.id)"
                      >Ignore</button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="redditImageData !== null"
      class="row ui"
    >
      <div class="col-md-3">
        <ImageCropper
          :entry-uuid="redditImageData.beforeId"
          :index="index"
          role="primary"
          type="before"
          @cropboxchanged="entryData.beforeCropInfo = $event"
          @primary-cropper-changed="primaryCropperChanged"
        />
      </div>
      <div class="col-md-3">
        <ImageCropper
          ref="secondarycropper"
          :entry-uuid="redditImageData.afterId"
          :index="index"
          role="secondary"
          type="after"
          @cropboxchanged="entryData.afterCropInfo = $event"
        />
      </div>
      <div class="col-md-3">
        <CropPreviews :index="index" />
        <div>
          <MapSearch
            :initial-guess="redditImageData.location"
            @locationchanged="poiData.location = $event"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="info">
          <div class="input-group input-group-lg full-width">
            <label for="postTitle">Title</label>
            <input
              id="postTitle"
              v-model="poiData.title"
              class="form-control"
              placeholder="The title of the post"
              type="text"
            >
          </div>
          <hr>
          <label for="title">Description <span class="optional-hint">(Optional)</span></label>
          <quill-editor
            v-model:content="poiData.description"
            content-type="html"
            placeholder="Describe your place.."
            theme="snow"
            toolbar="minimal"
            @ready="quillReady"
            @text-change="descriptionChanged"
          />
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="beforeYear">Before</label>
                <input
                  id="beforeYear"
                  v-model.number="entryData.beforeYear"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <label for="afterYear">After</label>
                <input
                  id="afterYear"
                  v-model.number="entryData.afterYear"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tags-container">
                <smart-tagz
                  :allow-duplicates="false"
                  :allow-paste="{delimiter: ','}"
                  :default-tags="poiData.tags"
                  :on-changed="tagChanged"
                  autosuggest
                  editable
                  input-placeholder="Tag your images.."
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span
                v-if="progress.crop === 'ok'"
                class="small-font"
              ><i class="fa fa-check success" /> Images</span>
              <span
                v-if="progress.crop === 'error'"
                class="small-font"
              ><i class="fa fa-times error" /> Images</span>
            </div>
            <div class="col-md-4">
              <span
                v-if="progress.poiCreate === 'ok'"
                class="small-font"
              ><i class="fa fa-check success" /> Point of Interest</span>
              <span
                v-if="progress.poiCreate === 'error'"
                class="small-font"
              ><i class="fa fa-times error" /> Point of Interest</span>
            </div>
            <div class="col-md-4">
              <span
                v-if="progress.share === 'ok'"
                class="small-font"
              ><i class="fa fa-check success" /> Sharing</span>
              <span
                v-if="progress.share === 'error'"
                class="small-font"
              ><i class="fa fa-times error" /> Sharing</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="buttons">
                <a
                  id="finish-poi"
                  class="btn btn-success btn-sm border-boxed button"
                  data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing.."
                  @click="submit()"
                >
                  Create
                </a>
                <a
                  class="btn btn-danger btn-sm border-boxed button"
                  @click="cancel()"
                >
                  <span>Cancel</span>
                </a>
                <a
                  class="btn btn-danger btn-sm border-boxed button"
                  @click="cancelAndIgnore()"
                >
                  <span>Cancel and ignore</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports
import { ref, reactive, computed, onMounted, inject } from 'vue';
import ImageCropper from '@/components/share/ImageCropper.vue';
import { SmartTagz } from 'smart-tagz';
import { QuillEditor } from '@vueup/vue-quill';
import type { Quill } from '@vueup/vue-quill';
import { Location } from '@aws-sdk/client-location';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import CropPreviews from '@/components/common/CropPreviews.vue';
import MapSearch from '@/components/common/MapSearch.vue';
import type { ProcessingResult } from '@/types/domain';
import type { Option } from '@/types/common';
import { AxiosInstance } from 'axios';
import { REDDIT_PREVIEW_URL}  from '@/config/urls'

const apiClient: AxiosInstance = inject('apiClient')!;


// Components declaration is not needed with <script setup>
// defineComponents is also not needed as it's implicit

// Constants
const OK_STATUS = 'ok';
const ERROR_STATUS = 'error';
const region = process.env.VUE_APP_AWS_REGION!;
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!;
const defaultTags = ['reddit'];

// State management
const locationClient = ref<Option<Location>>(undefined);
const hideButtons = reactive<Record<string, boolean>>({});
const redditPosts = ref<any[]>([]);
const redditScoreThreshold = ref(2000);
const tags = ref(defaultTags);
const input = ref('');
const redditPostId = ref<string | null>(null);
const redditImageData = ref<any | null>(null);
const index = ref(0);
const quill = ref<Option<Quill>>(undefined);
const secondaryCropper = ref(); // Reference to the secondary cropper component

import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore()

// Complex state objects
const poiData = reactive({
  title: '',
  description: '',
  tags: [...defaultTags],
  location: undefined as Option<any>
});

const entryData = reactive({
  beforeYear: null as null | number,
  afterYear: null as null | number,
  beforeCropInfo: null,
  afterCropInfo: null
});

const progress = reactive({
  crop: undefined as ProcessingResult,
  poiCreate: undefined as ProcessingResult,
  share: undefined as ProcessingResult
});

const newMarker = ref();

// Computed properties
const isValidPostIdOrUrl = computed(() => {
  const urlRegex = /^https:\/\/www\.reddit\.com\/r\/([a-zA-Z]*)\/comments\/([a-z0-9]{6})/i;
  const idRegex = /^[a-z0-9]{6}$/i;
  return redditPostId.value?.match(idRegex) != null ||
    redditPostId.value?.match(urlRegex) != null;
});

// Methods
const getRedditPosts = async () => {
  const response = await apiClient.get('/v2.0/admin/reddit', {
    params: {
      score: redditScoreThreshold.value
    }
  });

  if (response.status === 401) {
    authStore.logout()
  } else {
    redditPosts.value = response.data;
    redditPosts.value.sort((p1, p2) => p2.score - p1.score);
  }
};

const hideRedditPost = (postId: string) => {
  const idx = redditPosts.value.findIndex((post) => post.id === postId);
  redditPosts.value.splice(idx, 1);
};

const cancel = () => {
  redditPostId.value = null;
  redditImageData.value = null;
  input.value = '';
  Object.assign(poiData, {
    title: '',
    description: '',
    tags: [...defaultTags],
    location: undefined
  });
  Object.assign(entryData, {
    beforeYear: null,
    afterYear: null,
    beforeCropInfo: null,
    afterCropInfo: null
  });
  Object.assign(progress, {
    crop: undefined,
    poiCreate: undefined,
    share: undefined
  });
  newMarker.value = undefined;
};

const cancelAndIgnore = () => {
  if (redditPostId.value) {
    ignoreRedditPost(redditPostId.value);
    hideRedditPost(redditPostId.value);
  }
  cancel();
};

const parseHTML = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const stringIndicatesTodayDate = (title: string) => {
  const indicators = ['today', 'present', 'now'];
  return indicators.some(ind => title.toLowerCase().includes(ind));
};

const makeDescriptionHtml = (postData: any, title: string) => {
  return `<p>${title}</p><p><br></p><p>Uploaded to <a href="https://redd.it/${postData.postId}" rel="noopener noreferrer" target="_blank">reddit</a> by user <a href="https://www.reddit.com/user/${postData.postAuthor}" rel="noopener noreferrer" target="_blank">${postData.postAuthor}</a></p>`;
};

const submit = async () => {
  const poiJson = {
    title: poiData.title,
    descriptionRich: poiData.description,
    position: {
      lat: poiData.location!.lat,
      lng: poiData.location!.lng
    },
    tags: poiData.tags
  };

  const entryJson = {
    after: {
      uuid: redditImageData.value?.afterId,
      year: entryData.afterYear,
      cropInfo: entryData.afterCropInfo
    },
    before: {
      uuid: redditImageData.value?.beforeId,
      year: entryData.beforeYear,
      cropInfo: entryData.beforeCropInfo
    },
  };

  const submitButton = $('#finish-poi');
  submitButton.button('loading');

  try {
    // Process crops
    const cropResponse = await apiClient.post('/api/v1.0/entries/images/crop', entryJson);
    progress.crop = cropResponse.status >= 200 && cropResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Create POI
    const poiResponse = await apiClient.post('/api/v1.0/pois', poiJson);
    progress.poiCreate = poiResponse.status >= 200 && poiResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Link entry
    const linkResponse = await apiClient.post(
      `/api/v1.0/entries/${cropResponse.data.entryId}/pois`,
      { poiId: poiResponse.data.id }
    );
    progress.share = linkResponse.status >= 200 && linkResponse.status < 300 ?
      OK_STATUS : ERROR_STATUS;

    // Update Reddit metadata
    // await apiMetaHttp.put(`/admin/reddit/${redditPostId.value}/${poiResponse.data.id}`);
    // await apiMetaHttp.put(`/admin/reddit/${redditPostId.value}`);

    if (redditPostId.value) {
      hideRedditPost(redditPostId.value);
    }
    setTimeout(cancel, 1000);
  } finally {
    submitButton.button('reset');
  }
};

const ignoreRedditPost = async (redditPostId) => {
  // const response = await this.apiMetaHttp.delete(`/admin/reddit/${redditPostId}`)
  // console.log(response)
  hideRedditPost(redditPostId)
}

const processRedditPost = async (postId: string, formInput = false) => {
  redditPostId.value = postId;
  hideButtons[postId] = true;

  const submitButton = formInput ?
    $('#get-reddit-images') :
    $(`#submit_${postId}`);

  submitButton.button('loading');

  try {
    const resp = await apiClient.get(`/api/v1.0/admin/reddit/${postId}`);
    redditImageData.value = resp.data;

    // Update POI data
    poiData.title = parseHTML(redditImageData.value.title) ?? '';
    poiData.tags = [...defaultTags, ...redditImageData.value.tags];
    poiData.location = redditImageData.value.location;
    poiData.description = makeDescriptionHtml(
      redditImageData.value,
      poiData.title
    );

    // Update entry data
    entryData.beforeYear = redditImageData.value.beforeYear;
    entryData.afterYear = redditImageData.value.afterYear === undefined &&
    stringIndicatesTodayDate(poiData.title) ?
      new Date().getFullYear() :
      redditImageData.value.afterYear;
  } finally {
    submitButton.button('reset');
    hideButtons[postId] = false;
  }
};

// Event handlers
const descriptionChanged = () => {
  if (quill.value?.root) {
    poiData.description = quill.value.root.innerHTML;
  }
};

const tagChanged = (newTags: string[]) => {
  poiData.tags = newTags;
};

const quillReady = (q: Quill) => {
  quill.value = q;
};

const primaryCropperChanged = (event: { width: number; height: number }) => {
  secondaryCropper.value?.setAspectRatio(event.width / event.height);
};

// Lifecycle hooks
onMounted(async () => {
  locationClient.value = new Location({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({ region }),
      identityPoolId
    })
  });

  await getRedditPosts();
});

// Note: With <script setup>, all variables and functions are automatically exposed to the template
// No need for a return statement
</script>
<style scoped>

.crop-preview > .info {
  font-size: x-small;
  text-align: left;
}

.buttons {
  padding-right: 10px;
  padding-top: 10px;
  float: right;
}

.button {
  margin: 5px;
}

.map-column {
  z-index: 0;
  padding: 0px !important;
}

.full-width {
  width: 100%;
}

.url {
  padding: 10px 0px;
}

.ui {
  padding: 10px 0px;
}

.tags-container {
  padding: 15px 0px;
}

.info {
  text-align: left;
}

.title-cell {
  text-align: left;
}

.score-cell {
  text-align: right;
}

.button-cell {
  width: 15%;
}

.small-font {
  font-size: small;
}

.success {
  color: limegreen;
}

.error {
  color: crimson;
}

.button-in-cell {
  padding-right: 10px;
}

.freeform {
  padding-right: 18px;
}

.map {
  padding-top: 5px;
  width: 100%;
  height: calc(((100vw / 4) - (2 * 15px - 1px)) * 3 / 4);
  border: black solid 2px;
}

.preview-image {
  border: solid #0D0D0D 2px;
}

.category-header {
  text-align: left;
  padding: 10px 0px;
}

.reddit-refresh {
  padding-bottom: 15px;
}

.map-container {
  padding-top: 10px;
}
</style>
