import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  href: "#",
  class: "hidden"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "button-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "file-info"
}
const _hoisted_8 = { class: "file-info name" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "progress-indicator"
}
const _hoisted_11 = { class: "progress" }
const _hoisted_12 = { class: "progress-percent" }
const _hoisted_13 = ["id"]

import { onMounted, ref } from 'vue';
import { API_BASE_URL } from '@/config/urls';

import { Option } from '@/types/common';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploader',
  props: {
  type: {
    type: String,
    required: true,
    validator: (value: string) => ['before', 'after'].includes(value)
  }
},
  emits: ['uploaddone', 'cancel'],
  setup(__props, { emit: __emit }) {



const emit = __emit;


const uuid = ref((Math.random() * 1000000000000).toFixed(0));
const fileReceived = ref(false);
const tempId = ref<string | undefined>(undefined);
const uploadProgress = ref(-1);
const fileInfo = ref<Option<{
  width?: number,
  height?: number,
  name: string,
  size: number
}>>(undefined);

// const apiClient: AxiosInstance = inject('apiClient')

onMounted(() => {
  const uploadInputElement = $(`#upload_input_${uuid.value}`);
  const dropZone = $(`#upload_dropzone_${uuid.value}`);
  const maxWidth = dropZone.width()!;

  //@ts-ignore
  uploadInputElement.fileupload({
    url: `${API_BASE_URL}/v2.0/pois`,
    headers: {'Authorization': localStorage.jwtToken},
    dataType: 'json',
    autoUpload: true,
    acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
    maxFileSize: 5000000, // 5 MB
    disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
    previewMaxWidth: maxWidth,
    previewMaxHeight: maxWidth * 3 / 4,
    imageMaxWidth: 5000,
    imageMaxHeight: 5000,
    progressInterval: 30,
    previewCrop: false,
    previewCanvas: true,
    dropZone: dropZone
  }).on('fileuploaddone', (e, data) => {
    tempId.value = data.result.id;
    if (fileInfo.value) {
      fileInfo.value.width = data.result.originalWidth;
      fileInfo.value.height = data.result.originalHeight;
    }

    const file = data.files[0];
    $(`#upload_image_preview_${uuid.value}`).html(file.preview);
    fileReceived.value = true;

    console.log(`Upload successful, image has tempId=${tempId.value}`);
    emit('uploaddone', tempId.value);
  }).on('fileuploadfail', (e, data) => {
    const statusCode = data.response().jqXHR.status;
    console.log(`Upload failed with HTTP${statusCode}`);
  }).on('fileuploadadd', (e, data) => {
    fileInfo.value = {
      name: data.originalFiles[0].name,
      size: data.originalFiles[0].size
    };

    uploadProgress.value = 0;
  }).on('fileuploadprogress', (e, data) => {
    uploadProgress.value = data.loaded / data.total * 100;
  });
});

const startUpload = () => {
  $(`#upload_input_${uuid.value}`).click();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", _hoisted_1, [
      _createElementVNode("input", {
        id: `upload_input_${uuid.value}`,
        type: "file",
        name: `files[]`
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("button", {
      id: `upload_dropzone_${uuid.value}`,
      type: "button",
      class: "btn btn-primary btn-block",
      onClick: startUpload
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "glyphicon glyphicon-folder-open info-box" }, null, -1)),
      _createElementVNode("span", _hoisted_4, [
        _cache[0] || (_cache[0] = _createTextVNode("Upload a picture from ")),
        (__props.type === 'before')
          ? (_openBlock(), _createElementBlock("strong", _hoisted_5, "the past"))
          : _createCommentVNode("", true),
        (__props.type === 'after')
          ? (_openBlock(), _createElementBlock("strong", _hoisted_6, "today"))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_3),
    _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
    (fileInfo.value !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(fileInfo.value.name), 1),
          _createTextVNode(" - " + _toDisplayString((fileInfo.value.size / 1024).toFixed(0)) + "KB", 1),
          (fileInfo.value.width !== undefined)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - " + _toDisplayString(fileInfo.value.width) + "x" + _toDisplayString(fileInfo.value.height), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (uploadProgress.value > -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: _normalizeClass(["progress-bar", [uploadProgress.value >= 100 ? 'progress-bar-success' : 'progress-bar-warning']]),
              role: "progressbar",
              style: _normalizeStyle({ width: uploadProgress.value + '%' }),
              "aria-valuenow": "{{uploadProgress}}",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(uploadProgress.value.toFixed(0)) + "%", 1)
            ], 6)
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", {
        id: `upload_image_preview_${uuid.value}`,
        class: "preview"
      }, null, 8, _hoisted_13)
    ], 512), [
      [_vShow, fileReceived.value]
    ])
  ], 64))
}
}

})