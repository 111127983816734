<template>
  <NavBar />
  <div class="container-fluid">
    <div
      :key="1"
      class="share-dialog-container"
    >
      <div
        v-if="isVisible()"
        class="row share-place-row"
      >
        <transition name="fade">
          <ImageUploadStep
            v-if="displayUploadRow"
            :index="1"
            @done="uploadComplete"
            @cancel="cancel()"
          />
        </transition>
        <transition name="fade">
          <ImageCropStep
            v-if="displayCropperRow"
            :before-temp-id="uploadTempIds.before"
            :after-temp-id="uploadTempIds.after"
            :index="1"
            @done="cropComplete"
            @cancel="cancel()"
          />
        </transition>
        <transition name="fade">
          <CreatePointOfInterestStep
            v-if="displayMapRow"
            :entry-id="entryId"
            :index="1"
            @done="mapComplete"
            @cancel="cancel()"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'cropperjs/dist/cropper.css';
import { computed, onBeforeMount, ref } from 'vue';
import { decodeJwt } from 'jose'
import { useRouter } from 'vue-router';
import NavBar from '@/components/common/NavBar.vue';

import { JwtData } from '@/types/auth';
import ImageUploadStep from '@/components/share/ImageUploadStep.vue';
import CreatePointOfInterestStep from '@/components/share/CreatePointOfInterestStep.vue';
import ImageCropStep from '@/components/share/ImageCropStep.vue';

const router = useRouter()

enum Step {
  Upload = 'UPLOAD',
  Crop = 'CROP',
  Map = 'MAP',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}

defineProps<{
  index: number
}>()

const step = ref<Step>(Step.Upload)
const uploadTempIds = ref({
  before: '',
  after: ''
})
const entryId = ref('')

const displayCropperRow = computed((): Boolean => {
  return step.value == Step.Crop
})

const displayUploadRow = computed((): Boolean => {
  return step.value == Step.Upload
})

const displayMapRow = computed((): Boolean => {
  return step.value == Step.Map
})

const isVisible = (): Boolean => {
  return step.value != Step.Finished && step.value != Step.Canceled
}

const uploadComplete = (ids: any): void => {
  console.log(`Upload completed with ids ${JSON.stringify(ids)}`)
  uploadTempIds.value.after = ids.afterTempId
  uploadTempIds.value.before = ids.beforeTempId
  step.value = Step.Crop
}
const cropComplete = (cropInfo: any): void => {
  entryId.value = cropInfo.entryId
  step.value = Step.Map
}
const mapComplete = (): void => {
  step.value = Step.Finished
}

const cancel = (): void => {
  step.value = Step.Canceled
}


onBeforeMount(async () => {
  if (localStorage.token == undefined) {
    await router.push({name: 'login'})
  } else {

    // Not verifying the signature is fine here, as this only leads to a redirect for unauthenticated users.
    // Token will still be verified in the backend in next API interaction
    const token: JwtData = JSON.parse(localStorage.token);
    const tokenExpiration = decodeJwt(token.accessToken).exp!
    const now = Math.round(Date.now() / 1000)

    if (tokenExpiration < now) {
      await router.push({name: 'login'})
    }
  }
});

</script>

<style scoped>
.share-dialog-container {
  padding-top: 10px;
}

.share-place-row {
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>

