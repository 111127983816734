<template>
  <div class="left-container">
    <Navigation />
    <div
      id="map"
      class="map-container"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Navigation from './Navigation.vue'
import 'leaflet/dist/leaflet.css'
import { LatLng, Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet'
import {
  getOpenStreetMapAttribution,
  makeIconCreateFunction,
  makeRegularMarker,
  makeSelectedRegularMarker
} from '@/utils/maps'
import { Poi } from '@/types/domain';
import { IMAGES_BASE_URL } from '@/config/urls';
import { usePoiStore } from '@/stores/pois';

const markerIcon = makeRegularMarker()
const markerSelectedIcon = makeSelectedRegularMarker()
const router = useRouter()
const route = useRoute()

const poiStore = usePoiStore()

// https://stackoverflow.com/questions/65981712/uncaught-typeerror-this-map-is-null-vue-js-3-leaflet
const map = shallowRef();
const markers = ref<{ [key: string]: Marker }>({})

const layerOptions = {
  tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  id: 'mapbox/light-v10',
}

const markerClusterGroup = new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40,
  iconCreateFunction: makeIconCreateFunction('marker-cluster')
})

function preloadImages(poi: Poi) {
  new Image().src = `${IMAGES_BASE_URL}/${poi.id}/before.jpg`;
  new Image().src = `${IMAGES_BASE_URL}/${poi.id}/after.jpg`;
}

const renderMap = () => {
  if (map.value !== undefined && poiStore.hasPois) {
    for (let poiIdx in poiStore.pois) {
      const poi: Poi = poiStore.pois[poiIdx]
      const marker = new Marker([poi.location.lat, poi.location.lng], {icon: markerIcon})
      markerClusterGroup.addLayer(marker)

      marker.on('click', () => {
        poiStore.selectPoiByUuid(poi.id)
      })

      marker.on('mouseover', () => {
        preloadImages(poi);
      })
      markers.value[poi.id] = marker
    }

    if (route.params.id !== undefined) {
      const poi = poiStore.selectPoiByUuid(route.params.id as string)
      if (poi !== undefined) {
        map.value?.setView(
          new LatLng(
            poi.location.lat,
            poi.location.lng
          ),
          map.value?.getZoom()
        )
      } else {
        console.log('landing')
        router.push({name: 'landing'})
      }
    }

    map.value.addLayer(markerClusterGroup)
  }
}

watch(() => poiStore.$state.selectedPoiIdx, (newIdx, previousIdx) => {
  markers.value[poiStore.$state.pois[previousIdx].id].setIcon(markerIcon)
  markers.value[poiStore.$state.pois[newIdx].id].setIcon(markerSelectedIcon)

  router.push({name: 'poi', params: {id: poiStore.pois[newIdx].id}})
})

watch(() => poiStore.$state.pois, (pois) => {
  console.log(`${pois.length} Point of Interests fetched from API`)
  renderMap()
})

watch(() => poiStore.$state.automaticMapCenter, () => {
  map.value?.setView(
    new LatLng(
      poiStore.$state.automaticMapCenter!.lat,
      poiStore.$state.automaticMapCenter!.lng
    ),
    map.value?.getZoom()
  )
})

onMounted(async () => {
  map.value = new LeafletMap('map', {zoomControl: false}).setView([48.137154, 11.576124], 6)
  const tileLayer = new TileLayer(
    layerOptions.tileLayerUrl,
    {
      attribution: getOpenStreetMapAttribution(),
      maxZoom: 18,
      id: layerOptions.id,
    }
  )

  map.value.addLayer(tileLayer)

  navigator.geolocation?.getCurrentPosition(
    (position) => {
      map.value?.setView(new LatLng(position.coords.latitude, position.coords.longitude), 6)
    }
  )

  renderMap()
})
</script>

<style scoped>
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

.left-container {
  height: 100vh;
  width: calc(100vw - min(50vw, 800px));
  position: fixed;
  padding-left: 0px !important;
  padding-right: 0px !important;
  float: left;
}

.map-container {
  height: 100%;
  width: 100%;
}
</style>

<style lang="sass">
.marker-cluster
  background-color: rgba(187, 187, 187, 0.6)

  div
    background-color: rgba(145, 151, 142, 0.6)
</style>
