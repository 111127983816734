<template>
  <div>
    <div class="col-md-5">
      <ImageUploader
        type="before"
        @uploaddone="beforeUploadReady"
      />
    </div>
    <div class="col-md-5">
      <ImageUploader
        type="after"
        @uploaddone="afterUploadReady"
      />
    </div>
    <div class="col-md-2">
      <div
        v-if="uploadsReady()"
        class="buttons"
      >
        <a
          class="btn btn-success btn-sm border-boxed button"
          @click="next()"
        >
          <span>Continue</span>
        </a>
        <a
          class="btn btn-danger btn-sm border-boxed button"
          @click="cancel()"
        >
          <span>Cancel</span>
        </a>
      </div>
    </div>
  </div>
  <div>
    <div class="col-md-5">
      <ImageUploaderV2 />
    </div>
    <div class="col-md-5">
      <ImageUploaderV2 />
    </div>
    <div class="col-md-2">
      <div
        v-if="uploadsReady()"
        class="buttons"
      >
        <a
          class="btn btn-success btn-sm border-boxed button"
          @click="next()"
        >
          <span>Continue</span>
        </a>
        <a
          class="btn btn-danger btn-sm border-boxed button"
          @click="cancel()"
        >
          <span>Cancel</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import ImageUploader from '@/components/share/ImageUploader.vue'
import ImageUploaderV2 from '@/components/share/ImageUploaderV2.vue'

defineProps({
  index: {
      type: Number,
      default: 0
    }
})

const emit = defineEmits<{
  (e: 'done', data: any): void
  (e: 'cancel'): void
}>()

const beforeTempId = ref()
const afterTempId = ref()

const beforeUploadReady = (tempId: any) => {
  beforeTempId.value = tempId
}

const afterUploadReady = (tempId: any) => {
  afterTempId.value = tempId
}

const uploadsReady = () => {
  return beforeTempId.value !== undefined && afterTempId.value !== undefined
}

const next = () => {
  emit('done', { beforeTempId: beforeTempId.value, afterTempId: afterTempId.value })
}

const cancel = () => {
  emit('cancel')
}
</script>

<style scoped>
.buttons {
  padding-top: 10px;
  float: right;
}

.button {
  margin: 5px;
}
</style>
