import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  role: "navigation",
  class: "navbar navbar-default no-bottom-margin"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "nav navbar-nav" }
const _hoisted_5 = { class: "navbar-item" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "navbar-item" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "nav navbar-nav navbar-right" }
const _hoisted_10 = { class: "avatar-div avatar-non-transparent" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "navbar-text" }

import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

interface User {
  avatarUrl: string
  fullName: string
  role: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {

const router = useRouter()

const authStore = useAuthStore()

const getUser = (): User | undefined => {
  const userString = localStorage.getItem('user')
  return userString ? JSON.parse(userString) : undefined
}

const user = computed(() => getUser())
const isLoggedIn = computed(() => user.value !== undefined)

const logout = () => {
  authStore.logout()
  router.push({ name: 'landing' })
}

const navigateTo = (href: string) => {
  window.location.href = href
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (isLoggedIn.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createVNode(_component_router_link, {
                  to: "/",
                  custom: ""
                }, {
                  default: _withCtx(({ href }) => [
                    _createElementVNode("li", _hoisted_5, [
                      _createElementVNode("a", {
                        ref: "#",
                        onClick: ($event: any) => (navigateTo(href))
                      }, "Explore", 8, _hoisted_6)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/share",
                  custom: ""
                }, {
                  default: _withCtx(({ href }) => [
                    _createElementVNode("li", _hoisted_7, [
                      _createElementVNode("a", {
                        ref: "#",
                        onClick: ($event: any) => (navigateTo(href))
                      }, _cache[0] || (_cache[0] = [
                        _createElementVNode("span", { class: "selected" }, "Share", -1)
                      ]), 8, _hoisted_8)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("ul", _hoisted_9, [
                _createElementVNode("li", _hoisted_10, [
                  _createElementVNode("img", {
                    class: "avatar-img",
                    src: user.value?.avatarUrl,
                    alt: ""
                  }, null, 8, _hoisted_11)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(user.value?.fullName), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("li", {
                  role: "separator",
                  class: "divider"
                }, null, -1)),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "navbar-text",
                    onClick: logout
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("span", { class: "glyphicon glyphicon-log-out" }, null, -1)
                  ]))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "navbar-distance" }, null, -1))
  ], 64))
}
}

})