<template>
  <div class="poi-details">
    <BeforeAfterImagesContainer
      v-if="poiListNotEmpty"
      :selected-image-uuid="currentPoi.id"
    />
    <PointOfInterestInfo
      v-if="poiListNotEmpty"
      :poi="currentPoi"
    />
    <div v-if="poiListNotEmpty">
      <div
        v-if="authStore.user?.role === 'admin'"
        class="buttons"
      >
        <hr>
        <a
          v-if="currentPoi.status === 'standard'"
          class="btn btn-danger btn-sm border-boxed button"
          @click="disablePoi"
        >
          Disable
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import PointOfInterestInfo from '../common/PointOfInterestInfo.vue'
import { AxiosInstance } from 'axios';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import { useAuthStore } from '@/stores/auth';
import { usePoiStore } from '@/stores/pois';

const poiStore = usePoiStore()
const authStore = useAuthStore()
const apiClient: AxiosInstance = inject('apiClient')!

const poiListNotEmpty = computed(() => {
  return poiStore.hasPois
})

const currentPoi = computed(() => {
  return poiStore.selectedPoi!
})

const disablePoi = async () => {
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${currentPoi.value.id}/status`, {
    status: 'disabled'
  })
  if (poiResponse.status < 300) {
    currentPoi.value.status = 'disabled'
  }
}
</script>

<style scoped lang="sass">
.poi-details
  float: right
  text-align: center
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75)
  min-height: 100vh
  padding-left: 0px !important
  padding-right: 0px !important
  width: min(50vw, 800px)
  position: relative
  overflow-y: scroll
  height: 100vh

  .buttons
    text-align: right
    padding: 0px 20px
</style>
