<template>
  <div class="container-fluid">
    <div class="col-md-6">
      <h2>Frontend config</h2>
      <div class="config">
        <table class="table table-striped">
          <tbody>
            <tr
              v-for="(envVar) in frontendConfigValues"
              :key="envVar[0]"
            >
              <th scope="row">
                {{ envVar[0] }}
              </th>
              <td>{{ envVar[1] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr>
      <h2>API config</h2>
      <div class="config">
        <table class="table table-striped">
          <tbody>
            <tr
              v-for="(envVar) in backendConfigValues"
              :key="envVar[0]"
            >
              <th scope="row">
                {{ envVar[0] }}
              </th>
              <td>{{ envVar[1] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-6">
      <h2>Management</h2>
      <button
        type="button"
        class="btn btn-default"
        @click="populate"
      >
        <span
          class="glyphicon glyphicon-globe glyphicon-margin"
        />
        Populate DB
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, Ref } from 'vue';
import { AxiosInstance } from 'axios';
import { JSONValue } from '@/types/common';

const apiClient: AxiosInstance = inject('apiClient')!;

const frontendConfigValues = ref(
  Object.keys(process.env)
    .filter(k => k.startsWith('VUE_APP'))
    .map(k => ([k.replace('VUE_APP_', ''), process.env[k]]))
);
const backendConfigValues: Ref<JSONValue[]> = ref([]);

const populate = () => {
  console.log('populate');
};

onMounted(async () => {
  const version = await apiClient.get('/v2.0/config');
  const config = version.data
  for (const configValueName of Object.keys(config)) {
    backendConfigValues.value.push([configValueName, JSON.stringify(config[configValueName])])
  }
});
</script>

<style scoped>
.config {
  text-align: left;
  font-family: "Courier New",monospace;
}

.config .key {
  font-weight: bold;
}
</style>
