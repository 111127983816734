import { createApp } from 'vue'
import MainView from './views/MainView.vue'
import OAuthRedirectView from './views/OAuthRedirectView.vue'
import AppRouter from './Router.vue'
import ShareView from './views/ShareView.vue'
import LoginView from './views/LoginView.vue'
import AdminView from './views/AdminView.vue'
import * as VueRouter from 'vue-router'
import getAppConfig from './utils/config';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Aura from '@primevue/themes/aura';
import apiClient from '@/services/api/api.service';
import { createPinia } from 'pinia';
import './style.css';

const routes = [
  {path: '/', name: 'landing', component: MainView},
  {path: '/p/:id', name: 'poi', component: MainView},
  {path: '/login', name: 'login', component: LoginView},
  {path: '/share', component: ShareView},
  {path: '/admin', component: AdminView},
  {path: '/oauth', component: OAuthRedirectView},
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
})

const pinia = createPinia()

const app = createApp(AppRouter)

if (!apiClient) {
  console.log('ApiClient is undefined')
}

apiClient.get('/v2.0/config')
  .then((config) => {
    config.data.timestamp = Date.now();
    localStorage.setItem('config', JSON.stringify(config.data))

    console.log('Got config from API')
  })
  .catch(() => console.log('Failed to log config. Aborting'))

app.provide('apiClient', apiClient)
app.provide('getAppConfig', getAppConfig)
app.use(router)
app.use(pinia)
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: 'system',
      cssLayer: false
    }
  }
});
app.component('InputText', InputText);
app.component('FileUpload', FileUpload);
app.component('Button', Button);

app.mount('#app')
