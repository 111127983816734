import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, unref as _unref, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = { class: "col-md-4 crop-preview" }
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "row opacity-slider" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "row year-selector" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "input-group" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "input-group" }
const _hoisted_16 = { class: "buttons" }

import ImageCropper from './ImageCropper.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import apiClient from '@/services/api/api.service'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCropStep',
  props: {
    index: {},
    beforeTempId: {},
    afterTempId: {}
  },
  emits: ["done", "cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const secondaryCropper = ref()
const beforeCropInfo = ref()
const afterCropInfo = ref()
const beforeYear = ref<number | null>(null)
const afterYear = ref<number | null>(null)
const opacity = ref(0.5)

const primaryCropperChanged = (event: { width: number; height: number }) => {
  secondaryCropper.value.setAspectRatio(event.width / event.height)
}

const next = async () => {
      const body = {
        after: {
      uuid: props.afterTempId,
      year: afterYear.value,
      cropInfo: afterCropInfo.value
        },
        before: {
      uuid: props.beforeTempId,
      year: beforeYear.value,
      cropInfo: beforeCropInfo.value
        },
  }
  const submitButton = $('#next')
  submitButton.button('loading')

  const cropResponse = await apiClient.post('/api/v1.0/entries/images/crop', body)
  submitButton.button('reset')

  emit('done', cropResponse.data)
    }

const cancel = () => {
  emit('cancel')
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"row headers\" data-v-ab4204b8><div class=\"col-md-4\" data-v-ab4204b8><div class=\"title\" data-v-ab4204b8><h4 data-v-ab4204b8>Your place in the past</h4></div></div><div class=\"col-md-4\" data-v-ab4204b8><div class=\"title\" data-v-ab4204b8><h4 data-v-ab4204b8>Your place today</h4></div></div><div class=\"col-md-4\" data-v-ab4204b8><div class=\"title\" data-v-ab4204b8><h4 data-v-ab4204b8>Preview</h4></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ImageCropper, {
          role: "primary",
          index: props.index,
          "entry-uuid": props.beforeTempId,
          type: 'before',
          onCropboxchanged: _cache[0] || (_cache[0] = ($event: any) => (beforeCropInfo.value = $event)),
          onPrimaryCropperChanged: primaryCropperChanged
        }, null, 8, ["index", "entry-uuid"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(ImageCropper, {
          ref_key: "secondaryCropper",
          ref: secondaryCropper,
          role: "secondary",
          index: props.index,
          "entry-uuid": props.afterTempId,
          type: 'after',
          onCropboxchanged: _cache[1] || (_cache[1] = ($event: any) => (afterCropInfo.value = $event))
        }, null, 8, ["index", "entry-uuid"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[12] || (_cache[12] = _createElementVNode("div", null, [
          _createElementVNode("div", { class: "info left-aligned" }, " This is how your images will overlap after cropping. Use the croppers and slider to finetune the results. ")
        ], -1)),
        _createElementVNode("div", {
          id: 'previews_'+props.index,
          class: "images"
        }, [
          _createElementVNode("div", {
            id: 'previews_before_'+props.index,
            class: "image-container"
          }, null, 8, _hoisted_7),
          _createElementVNode("div", {
            id: 'previews_after_'+props.index,
            style: _normalizeStyle({ opacity: opacity.value }),
            class: "image-container overlay"
          }, null, 12, _hoisted_8)
        ], 8, _hoisted_6),
        _createElementVNode("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-md-3" }, [
            _createElementVNode("div", null, " Past ")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(VueSlider), {
              modelValue: opacity.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((opacity).value = $event)),
              modelModifiers: { number: true },
              min: 0,
              max: 1,
              interval: 0.1,
              tooltip: "none"
            }, null, 8, ["modelValue"])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-md-3" }, [
            _createElementVNode("div", null, " Today ")
          ], -1))
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "beforeYear" }, [
                _createTextVNode("Year of the past photo "),
                _createElementVNode("span", { class: "optional-hint" }, "(Optional)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                id: "beforeYear",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((beforeYear).value = $event)),
                class: "form-control",
                type: "number"
              }, null, 512), [
                [
                  _vModelText,
                  beforeYear.value,
                  void 0,
                  { number: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "afterYear" }, [
                _createTextVNode("Year of the recent photo "),
                _createElementVNode("span", { class: "optional-hint" }, "(Optional)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                id: "afterYear",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((afterYear).value = $event)),
                class: "form-control",
                type: "number"
              }, null, 512), [
                [
                  _vModelText,
                  afterYear.value,
                  void 0,
                  { number: true }
                ]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("a", {
            id: "next",
            "data-loading-text": "<i class='fa fa-circle-o-notch fa-spin'></i> Cropping..",
            class: "btn btn-success btn-sm border-boxed button",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (next()))
          }, " Continue "),
          _createElementVNode("a", {
            class: "btn btn-danger btn-sm border-boxed button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (cancel()))
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("span", null, "Cancel", -1)
          ]))
        ])
      ])
    ])
  ]))
}
}

})