<template>
  <a
    href="#"
    class="hidden"
  >
    <input
      :id="`upload_input_${uuid}`"
      type="file"
      :name="`files[]`"
    >
  </a>
  <button
    :id="`upload_dropzone_${uuid}`"
    type="button"
    class="btn btn-primary btn-block"
    @click="startUpload"
  >
    <span class="glyphicon glyphicon-folder-open info-box" />
    <span class="button-text">Upload a picture from
      <strong v-if="type === 'before'">the past</strong>
      <strong v-if="type === 'after'">today</strong>
    </span>
  </button>
  <hr>
  <div
    v-if="fileInfo !== undefined"
    class="file-info"
  >
    <span class="file-info name">{{ fileInfo.name }}</span> - {{ (fileInfo.size / 1024).toFixed(0) }}KB<span
      v-if="fileInfo.width !== undefined"
    > - {{ fileInfo.width }}x{{ fileInfo.height }}</span>
  </div>
  <div
    v-if="uploadProgress > -1"
    class="progress-indicator"
  >
    <div class="progress">
      <div
        class="progress-bar"
        :class="[uploadProgress >= 100 ? 'progress-bar-success' : 'progress-bar-warning']"
        role="progressbar"
        :style="{ width: uploadProgress + '%' }"
        aria-valuenow="{{uploadProgress}}"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span class="progress-percent">{{ uploadProgress.toFixed(0) }}%</span>
      </div>
    </div>
  </div>
  <div v-show="fileReceived">
    <hr>
    <div
      :id="`upload_image_preview_${uuid}`"
      class="preview"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { API_BASE_URL } from '@/config/urls';

import { Option } from '@/types/common';

defineProps({
  type: {
    type: String,
    required: true,
    validator: (value: string) => ['before', 'after'].includes(value)
  }
});

const emit = defineEmits(['uploaddone', 'cancel']);


const uuid = ref((Math.random() * 1000000000000).toFixed(0));
const fileReceived = ref(false);
const tempId = ref<string | undefined>(undefined);
const uploadProgress = ref(-1);
const fileInfo = ref<Option<{
  width?: number,
  height?: number,
  name: string,
  size: number
}>>(undefined);

// const apiClient: AxiosInstance = inject('apiClient')

onMounted(() => {
  const uploadInputElement = $(`#upload_input_${uuid.value}`);
  const dropZone = $(`#upload_dropzone_${uuid.value}`);
  const maxWidth = dropZone.width()!;

  //@ts-ignore
  uploadInputElement.fileupload({
    url: `${API_BASE_URL}/v2.0/pois`,
    headers: {'Authorization': localStorage.jwtToken},
    dataType: 'json',
    autoUpload: true,
    acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
    maxFileSize: 5000000, // 5 MB
    disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
    previewMaxWidth: maxWidth,
    previewMaxHeight: maxWidth * 3 / 4,
    imageMaxWidth: 5000,
    imageMaxHeight: 5000,
    progressInterval: 30,
    previewCrop: false,
    previewCanvas: true,
    dropZone: dropZone
  }).on('fileuploaddone', (e, data) => {
    tempId.value = data.result.id;
    if (fileInfo.value) {
      fileInfo.value.width = data.result.originalWidth;
      fileInfo.value.height = data.result.originalHeight;
    }

    const file = data.files[0];
    $(`#upload_image_preview_${uuid.value}`).html(file.preview);
    fileReceived.value = true;

    console.log(`Upload successful, image has tempId=${tempId.value}`);
    emit('uploaddone', tempId.value);
  }).on('fileuploadfail', (e, data) => {
    const statusCode = data.response().jqXHR.status;
    console.log(`Upload failed with HTTP${statusCode}`);
  }).on('fileuploadadd', (e, data) => {
    fileInfo.value = {
      name: data.originalFiles[0].name,
      size: data.originalFiles[0].size
    };

    uploadProgress.value = 0;
  }).on('fileuploadprogress', (e, data) => {
    uploadProgress.value = data.loaded / data.total * 100;
  });
});

const startUpload = () => {
  $(`#upload_input_${uuid.value}`).click();
};
</script>

<style scoped>

.preview {
  margin-top: 30px;
  border: #666666 solid 1px;
}

.progress-indicator {
  padding: 10px 0px;
}

.button-text {
  padding-left: 10px;
}

.progress-percent {
  padding-left: 10px;
}

.file-info {
  font-size: small;
  text-align: left;
}

.file-info .name {
  font-weight: bold;
}


</style>
