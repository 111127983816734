<template>
  <NavBar />
  <div class="ng-scope">
    <ul
      id="images_tabs"
      class="nav nav-tabs"
    >
      <li class="tab active">
        <a
          href="#map"
          data-toggle="tab"
        >
          <span class="nav-tab-text">Map</span>
        </a>
      </li>
      <li class="tab">
        <a
          href="#users"
          data-toggle="tab"
        >
          <span class="nav-tab-text">Users</span>
        </a>
      </li>
      <li class="tab">
        <a
          href="#general"
          data-toggle="tab"
        >
          <span class="nav-tab-text">General</span>
        </a>
      </li>
      <li class="tab">
        <a
          href="#reddit"
          data-toggle="tab"
        >
          <span class="nav-tab-text">Reddit</span>
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div
        id="map"
        class="tab-pane active"
      >
        <AdminPoiMap />
      </div>
      <div
        id="users"
        class="tab-pane"
      >
        <UserManagement />
      </div>
      <div
        id="general"
        class="tab-pane"
      >
        <GeneralInformation poi="undefined" />
      </div>
      <div
        id="reddit"
        class="tab-pane"
      >
        <RedditImport />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AdminPoiMap from '@/components/admin/AdminPoiMap.vue';
import UserManagement from '@/components/admin/UserManagement.vue';

import NavBar from '@/components/common/NavBar.vue';
import GeneralInformation from '@/components/admin/GeneralInformation.vue';
import RedditImport from '@/components/admin/RedditImport.vue';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

onBeforeMount(async () => {
  if (!localStorage.idToken) {
    await router.push({ name: 'login' })
  }
})

</script>
<style>

</style>
