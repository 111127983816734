import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'
import '../../assets/js/twentytwenty/jquery.twentytwenty.js'
import '../../assets/js/twentytwenty/jquery.event.move.js'

import { IMAGES_BASE_URL } from '@/config/urls'
import { usePoiStore } from '@/stores/pois';

interface Props {
  entryId: string
  type: 'before' | 'after'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BeforeAfterImage',
  props: {
    entryId: {},
    type: {}
  },
  setup(__props: any) {

const props = __props

const poiStore = usePoiStore()

const imageUrl = computed(() => {
  return `${IMAGES_BASE_URL}/${props.entryId}/${props.type}.jpg`
})

const onLoad = () => {
  poiStore.setMainBeforeAfterReadyStatus({
    imageType: props.type,
    isReady: true
  })

  if (poiStore.isBeforeAfterReady) {
    if (!poiStore.isSliderInitialized) {
      poiStore.setMainSliderInitState(true)
      // @ts-ignore
      $('#sidebar-before-after').twentytwenty({
        no_overlay: true
      })
    } else {
      $(window).trigger('resize.twentytwenty')
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    src: imageUrl.value,
    alt: "",
    onLoad: onLoad
  }, null, 40, _hoisted_1))
}
}

})