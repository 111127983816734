import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-message" }

import { inject, onMounted } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { FrontendConfig, JwtData } from '@/types/auth';
import { useAuthStore } from '@/stores/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'OAuthRedirectView',
  setup(__props) {

const route = useRoute()

const authStore = useAuthStore()
const router = useRouter()
const getAppConfig: () => Promise<FrontendConfig> = inject('getAppConfig')!;


onMounted(async () => {
  const code = route.query.code
  const config = await getAppConfig()

  const redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URL!

  const resp = await axios.post(`https://${config.auth.baseUrl}/oauth2/token`, new URLSearchParams({
    code: code!.toString(),
    grant_type: 'authorization_code',
    client_id: config.auth.appClientId,
    redirect_uri: redirectUri
  }), {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    validateStatus: () => true
  })

  const jwtData: JwtData = {
    idToken: resp.data.id_token,
    accessToken: resp.data.access_token,
    refreshToken: resp.data.refresh_token,
    expiresIn: resp.data.expires_in,
    tokenType: resp.data.token_type,
  }
  authStore.login(jwtData)
  console.log('Exchanged Authorization Code for JWT Token')

  await router.push({name: 'landing'});
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, " Logging you in.. "))
}
}

})