import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-md-3 poi-details" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "poi-id-text"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = {
  key: 1,
  class: "pt-4 text-xl"
}

import { inject, onMounted, Ref, ref, shallowRef } from 'vue';
import 'leaflet/dist/leaflet.css';
import { Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet';
import PointOfInterestInfo from '@/components/common/PointOfInterestInfo.vue';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import {
  makeDisabledMarker,
  makeRegularMarker,
  makeSelectedDisabledMarker,
  makeSelectedRegularMarker
} from '@/utils/maps';
import { useRouter } from 'vue-router';
import { Poi } from '@/types/domain';
import { Option } from '@/types/common';
import { AxiosInstance } from 'axios';
import { useAuthStore } from '@/stores/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminPoiMap',
  setup(__props) {

const markerIcon = makeRegularMarker();
const markerIconSelected = makeSelectedRegularMarker();
const markerIconDisabled = makeDisabledMarker();
const markerIconDisabledSelected = makeSelectedDisabledMarker();

const router = useRouter();

const authStore = useAuthStore()
const apiClient: AxiosInstance = inject('apiClient')!;

const markerClusterGroup = shallowRef(new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40
}));
const pois = ref<Poi[]>([]);
const selectedPoi = ref<Option<Poi>>(undefined);
const map = ref<Option<LeafletMap>>(undefined);
const deletedPois = ref({});
const selectedMarker = shallowRef<Option<Marker>>(undefined);
const deleteConfirmation = ref(false);
const layerOptions = {
  tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  id: 'mapbox/light-v10'
};

const deletePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }

  const poiResponse = await apiClient.delete(`/api/v1.0/pois/${selectedPoi.value.id}`);

  if (poiResponse.status < 300) {
    const index = pois.value.indexOf(selectedPoi.value);
    if (index > -1) {
      pois.value.splice(index, 1);
    }

    map.value?.removeLayer(selectedMarker.value!);

    deletedPois.value[selectedPoi.value.id] = true;
    deleteConfirmation.value = false;
  }
};

const disablePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${selectedPoi.value.id}/status`, {
    status: 'disabled'
  });

  if (poiResponse.status < 300) {
    selectedPoi.value.status = 'disabled';
    selectedMarker.value?.setIcon(markerIconDisabledSelected);
  }
};

const enablePoi = async () => {
  if (selectedPoi.value === undefined) {
    return;
  }
  const poiResponse = await apiClient.put(`/api/v1.0/admin/pois/${selectedPoi.value.id}/status`, {
    status: 'standard'
  });

  if (poiResponse.status < 300) {
    selectedPoi.value.status = 'standard';
    selectedMarker.value?.setIcon(markerIconSelected);
  }
};

onMounted(async () => {
  const poiResponse = await apiClient.get('/v2.0/pois');

  if (poiResponse.status === 401) {
    authStore.logout()
    await router.push({name: 'login'});
  }

  pois.value = poiResponse.data.pois;
  if (map.value === undefined) {
    map.value = new LeafletMap('mapContainer').setView([48.137154, 11.576124], 5);
  }

  const tileLayer = new TileLayer(
    layerOptions.tileLayerUrl,
    {
      attribution:
        'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: layerOptions.id,
    }
  );

  map.value.addLayer(tileLayer);

  for (const poi of pois.value) {
    const marker: Marker = new Marker([poi.location.lat, poi.location.lng], {
      icon: poi.status === 'disabled' ? markerIconDisabled : markerIcon
    });

    marker.on('click', () => {
      selectedMarker.value?.setIcon(selectedPoi.value?.status === 'disabled' ? markerIconDisabled : markerIcon);
      selectedPoi.value = poi;
      selectedMarker.value = marker;
      selectedMarker.value.setIcon(selectedPoi.value.status === 'disabled' ? markerIconDisabledSelected : markerIconSelected);
      $(window).trigger('resize.twentytwenty');
    });

    markerClusterGroup.value.addLayer(marker);
  }

  map.value.addLayer(markerClusterGroup.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-md-9 map-column" }, [
      _createElementVNode("div", {
        id: "mapContainer",
        class: "map-container"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      (selectedPoi.value !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(BeforeAfterImagesContainer, {
                "selected-image-uuid": selectedPoi.value.id
              }, null, 8, ["selected-image-uuid"])
            ]),
            (!deletedPois.value[selectedPoi.value.id])
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(selectedPoi.value.id), 1))
              : _createCommentVNode("", true),
            (deletedPois.value[selectedPoi.value.id])
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[4] || (_cache[4] = [
                  _createElementVNode("div", {
                    class: "alert alert-danger",
                    role: "alert"
                  }, " POI deleted ", -1)
                ])))
              : _createCommentVNode("", true),
            (!deletedPois.value[selectedPoi.value.id])
              ? (_openBlock(), _createBlock(PointOfInterestInfo, {
                  key: 2,
                  poi: selectedPoi.value
                }, null, 8, ["poi"]))
              : _createCommentVNode("", true),
            (!deletedPois.value[selectedPoi.value.id])
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    (!deleteConfirmation.value)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "btn btn-danger btn-sm border-boxed button",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (deleteConfirmation.value = true))
                        }, " Delete "))
                      : _createCommentVNode("", true),
                    (deleteConfirmation.value)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          class: "btn btn-danger btn-sm border-boxed button",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (deletePoi()))
                        }, " Are you sure? "))
                      : _createCommentVNode("", true),
                    (selectedPoi.value.status==='standard')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 2,
                          class: "btn btn-warning btn-sm border-boxed button",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (disablePoi()))
                        }, " Disable "))
                      : _createCommentVNode("", true),
                    (selectedPoi.value.status==='disabled')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 3,
                          class: "btn btn-success btn-sm border-boxed button",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (enablePoi()))
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("span", null, "Enable", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, " Select a marker "))
    ])
  ], 64))
}
}

})