import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import FileUpload, { FileUploadSelectEvent } from 'primevue/fileupload';
import { v7 as uuidv7 } from 'uuid';

import { API_BASE_URL } from '@/config/urls';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploaderV2',
  setup(__props) {

function onUpload(event) {
  console.log('onUpload', event)
}

function onSelect(event: FileUploadSelectEvent ) {
  const imageUuid = uuidv7()
  console.log('onSelect', event.files[0].name)
  console.log('onSelect', imageUuid)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(FileUpload), {
      name: "demo[]",
      url: `${_unref(API_BASE_URL)}/v2.0/images`,
      multiple: false,
      accept: "image/*",
      "max-file-size": 1000000,
      "preview-width": "200",
      onUpload: _cache[0] || (_cache[0] = ($event: any) => (onUpload($event))),
      onSelect: _cache[1] || (_cache[1] = ($event: any) => (onSelect($event)))
    }, {
      empty: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("span", null, "Upload a picture from the past", -1)
      ])),
      _: 1
    }, 8, ["url"])
  ]))
}
}

})