import { useAuthStore } from '@/stores/auth';

import jwt_decode from 'jwt-decode';
import { refreshAccessToken } from '@/services/auth/oauth.service';

import { CognitoAuthResponse, JwtData } from '@/types/auth';

export async function trySilentLogin() {
  const authStore = useAuthStore()
  const jwtString: string | null = localStorage.getItem('token')
  if (jwtString === null) {
    console.log('No user logged in.')
    authStore.logout()
    return false
  } else {
    const existingJwt: JwtData = JSON.parse(jwtString)
    const accessTokenData: any = jwt_decode(existingJwt.accessToken)

    if (accessTokenData.exp < Date.now() / 1000) {
      console.log('User access token expired - Refreshing')
      const refreshResponse: undefined | CognitoAuthResponse = await refreshAccessToken(existingJwt.refreshToken)

      if (refreshResponse === undefined) {
        console.log('Token refresh failed, logging user out')
        authStore.logout()
        return false
      } else {
        const updatedJwtData: JwtData = {
          idToken: refreshResponse.id_token,
          accessToken: refreshResponse.access_token,
          expiresIn: refreshResponse.expires_in,
          tokenType: refreshResponse.token_type,
          refreshToken: existingJwt.refreshToken
        }
        authStore.login(updatedJwtData)
        console.log('User is logged in after refreshing access token')
      }
    } else {
      authStore.login(existingJwt)
      console.log('User is logged in')
    }

    return true
  }
}