import { defineStore } from 'pinia'

// Define the store's state interface for better type safety
interface FavoritesState {
  favorites: string[]
}

// Define the favorites store using the setup syntax
export const useFavoritesStore = defineStore('favorites', {
  // State is a function that returns an object
  state: (): FavoritesState => ({
    favorites: loadInitialFavorites(),
  }),

  // Getters are like computed properties
  getters: {
    // Get the list of favorites
    getFavorites: (state) => state.favorites,

    // Check if a POI is in favorites
    isFavorite: (state) => (poiId: string) => {
      return state.favorites.includes(poiId)
    },

    // Get the count of favorites
    favoritesCount: (state) => state.favorites.length,
  },

  // Actions are methods that can modify the state
  actions: {
    // Set the entire favorites list
    setFavorites(favorites: string[]) {
      localStorage.setItem('favorites', JSON.stringify(favorites))
      this.favorites = favorites
    },

    // Add a POI to favorites
    addToFavorites(poiId: string) {
      // Avoid duplicates
      if (!this.isFavorite(poiId)) {
        const updatedFavorites = [...this.favorites, poiId]
        this.setFavorites(updatedFavorites)
      }
    },

    // Remove a POI from favorites
    removeFromFavorites(poiId: string) {
      const updatedFavorites = this.favorites.filter(id => id !== poiId)
      this.setFavorites(updatedFavorites)
    },

    // Toggle favorite status
    toggleFavorite(poiId: string) {
      if (this.isFavorite(poiId)) {
        this.removeFromFavorites(poiId)
      } else {
        this.addToFavorites(poiId)
      }
    },
  },
})

// Helper function to load initial favorites from localStorage
function loadInitialFavorites(): string[] {
  const favoritesStr = localStorage.getItem('favorites')
  return favoritesStr ? JSON.parse(favoritesStr) : []
}