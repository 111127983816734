import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_2 = {
  "data-toggle": "dropdown",
  class: "dropdown-toggle",
  href: "#"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  role: "menu",
  class: "dropdown-menu left loggedin"
}
const _hoisted_5 = { class: "username" }
const _hoisted_6 = ["onClick"]

import { useAuthStore } from '@/stores/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserMenu',
  setup(__props) {


const authStore = useAuthStore()
const logout = async () => {
  authStore.logout()
}

const navigateTo = async (href: string) => {
  window.location.href = href;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_unref(authStore).user !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", _hoisted_2, [
          _createElementVNode("img", {
            class: "avatar-img",
            src: _unref(authStore).user.picture,
            alt: "Picture"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("h5", null, [
              _createElementVNode("b", null, _toDisplayString(_unref(authStore).userFullName), 1)
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("li", { class: "divider reduced-top-margin" }, null, -1)),
          _createVNode(_component_router_link, {
            to: "/admin",
            custom: ""
          }, {
            default: _withCtx(({ href }) => [
              (_unref(authStore).isAdmin)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: "menu-item",
                    onClick: ($event: any) => (navigateTo(href))
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("span", { class: "xs-font padded-glyphicon glyphicon glyphicon-cog" }, null, -1),
                    _createTextVNode(" Admin ")
                  ]), 8, _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createElementVNode("li", { class: "divider" }, null, -1)),
          _createElementVNode("li", {
            class: "menu-item",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (logout()))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("span", { class: "xs-font padded-glyphicon glyphicon glyphicon-log-out" }, null, -1),
            _createTextVNode(" Logout ")
          ]))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})