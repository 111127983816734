<script setup lang="ts">
import FileUpload, { FileUploadSelectEvent } from 'primevue/fileupload';
import { v7 as uuidv7 } from 'uuid';

import { API_BASE_URL } from '@/config/urls';

function onUpload(event) {
  console.log('onUpload', event)
}

function onSelect(event: FileUploadSelectEvent ) {
  const imageUuid = uuidv7()
  console.log('onSelect', event.files[0].name)
  console.log('onSelect', imageUuid)
}
</script>

<template>
  <div>
    <FileUpload
      name="demo[]"
      :url="`${API_BASE_URL}/v2.0/images`"
      :multiple="false"
      accept="image/*"
      :max-file-size="1000000"
      preview-width="200"
      @upload="onUpload($event)"
      @select="onSelect($event)"
    >
      <template #empty>
        <span>Upload a picture from the past</span>
      </template>
    </FileUpload>
  </div>
</template>

<style scoped>

</style>