<template>
  <div class="navigation left-side">
    <router-link
      v-if="false"
      v-slot="{ href }"
      custom
      to="/share"
    >
      <button
        class="btn btn-default navbar-btn"
        type="button"
        @click="navigateTo(href)"
      >
        <span class="glyphicon glyphicon-picture glyphicon-margin" /><span class="button-text">Share images</span>
      </button>
    </router-link>
    <button
      class="btn btn-primary navbar-btn explore-button"
      type="button"
      @click="getRandomPoi"
    >
      <span class="glyphicon glyphicon-random glyphicon-margin" /><span class="button-text">Random place</span>
    </button>
  </div>
  <div class="navigation right-side">
    <transition name="fade">
      <div v-if="authStore.user === undefined">
        <LoginMenu />
      </div>
    </transition>
    <div v-if="authStore.user !== undefined">
      <UserMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
import LoginMenu from '@/components/main/LoginMenu.vue';
import UserMenu from '@/components/main/UserMenu.vue';
import { useAuthStore } from '@/stores/auth';
import { usePoiStore } from '@/stores/pois';
const authStore = useAuthStore();
const poiStore = usePoiStore()

const navigateTo = async (href: string) => {
  window.location.href = href;
}

const getRandomPoi = async () => {
  poiStore.selectRandomPoi()
}

</script>

<style scoped>
.navigation {
  position: absolute;
  top: 0;
  z-index: 10000;
}

.right-side {
  right: 8px;
}

.left-side {
  left: 8px;
}

.explore-button {
  margin-left: 8px;
}

.button-text {
  padding-left: 10px;
}


</style>
