import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-md-5" }
const _hoisted_2 = { class: "col-md-5" }
const _hoisted_3 = { class: "col-md-2" }
const _hoisted_4 = {
  key: 0,
  class: "buttons"
}
const _hoisted_5 = { class: "col-md-5" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "col-md-2" }
const _hoisted_8 = {
  key: 0,
  class: "buttons"
}

import { ref } from 'vue'
import ImageUploader from '@/components/share/ImageUploader.vue'
import ImageUploaderV2 from '@/components/share/ImageUploaderV2.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploadStep',
  props: {
  index: {
      type: Number,
      default: 0
    }
},
  emits: ["done", "cancel"],
  setup(__props, { emit: __emit }) {



const emit = __emit

const beforeTempId = ref()
const afterTempId = ref()

const beforeUploadReady = (tempId: any) => {
  beforeTempId.value = tempId
}

const afterUploadReady = (tempId: any) => {
  afterTempId.value = tempId
}

const uploadsReady = () => {
  return beforeTempId.value !== undefined && afterTempId.value !== undefined
}

const next = () => {
  emit('done', { beforeTempId: beforeTempId.value, afterTempId: afterTempId.value })
}

const cancel = () => {
  emit('cancel')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(ImageUploader, {
          type: "before",
          onUploaddone: beforeUploadReady
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(ImageUploader, {
          type: "after",
          onUploaddone: afterUploadReady
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (uploadsReady())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                class: "btn btn-success btn-sm border-boxed button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (next()))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("span", null, "Continue", -1)
              ])),
              _createElementVNode("a", {
                class: "btn btn-danger btn-sm border-boxed button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (cancel()))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("span", null, "Cancel", -1)
              ]))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(ImageUploaderV2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(ImageUploaderV2)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (uploadsReady())
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("a", {
                class: "btn btn-success btn-sm border-boxed button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (next()))
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("span", null, "Continue", -1)
              ])),
              _createElementVNode("a", {
                class: "btn btn-danger btn-sm border-boxed button",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (cancel()))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("span", null, "Cancel", -1)
              ]))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})