<template>
  <vue-cropper
    ref="cropper"
    :src="imageSource"
    alt="Source Image"
    :initial-aspect-ratio="aspectRatio"
    :multiple="true"
    :view-mode="3"
    :moveable="false"
    :rotatable="false"
    :scalable="false"
    :zoomable="false"
    :preview="previewDiv"
    :check-cross-origin="false"
    @crop="handleCrop"
  />
</template>
<script lang="ts" setup>
//@ts-ignore
import VueCropper from 'vue-cropperjs'
import { ref, computed, onMounted } from 'vue'
import { IMAGES_BASE_URL } from '@/config/urls'

const props = defineProps<{
  index: number
  entryUuid: string
  type: string
  role: string
}>()

const emit = defineEmits<{
  (e: 'cropboxchanged', cropInfo: any): void
  (e: 'primaryCropperChanged', cropBoxData: any): void
}>()

const cropper = ref()
const cropBoxData = ref()
const aspectRatio = ref(16 / 9)

const previewDiv = computed(() => {
  return `#previews_${props.type}_${props.index}`
})

const imageSource = computed(() => {
  return `${IMAGES_BASE_URL}/tmp/${props.entryUuid}.jpg`
})

onMounted(() => {
  cropBoxData.value = cropper.value.getCropBoxData()
})

const setAspectRatio = (ratio: number) => {
  const currentCropBox = cropper.value.getCropBoxData()
  const newCropBox = JSON.parse(JSON.stringify(currentCropBox))
  newCropBox.height = currentCropBox.width / ratio

  // Fix the aspect ratio to the given value
  cropper.value.setAspectRatio(ratio)
  // Resets the crop box to the last known position
  cropper.value.setCropBoxData(newCropBox)
}

const handleCrop = () => {
  if (cropper.value === null) {
    return
  }

  cropBoxData.value = cropper.value.getCropBoxData()
  const imageWidth = cropper.value.getImageData().width
  const imageHeight = cropper.value.getImageData().height
  const cropInfo = {
    left: cropper.value.getCropBoxData().left / imageWidth,
    right: (cropper.value.getCropBoxData().left + cropper.value.getCropBoxData().width) / imageWidth,
    top: cropper.value.getCropBoxData().top / imageHeight,
    bottom: (cropper.value.getCropBoxData().top + cropper.value.getCropBoxData().height) / imageHeight,
  }
  emit('cropboxchanged', cropInfo)
  if (props.role === 'primary') {
    emit('primaryCropperChanged', cropper.value.getCropBoxData())
  }
}
</script>
