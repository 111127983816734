<template>
  <div>
    <div
      id="map-container"
      class="map-container"
    />
    <div class="marker-explanation-row">
      <span class="marker-explanation"><img
        height="15"
        src="/img/markers/new_poi.png"
        alt=""
      > Current Selection</span>
      <span class="marker-explanation"><img
        height="15"
        src="/img/markers/photo.png"
        alt=""
      > Initial Guess</span>
      <span class="marker-explanation"><img
        height="15"
        src="/img/markers/photo_geocoded_blue.png"
        alt=""
      > Search Result</span>
    </div>
    <div class="searchbar">
      <div class="input-group">
        <input
          v-model="location"
          class="form-control"
          placeholder="Search location"
          @keyup.enter="geocode()"
        >
        <span class="input-group-btn">
          <button
            class="btn btn-primary"
            type="button"
            @click="geocode()"
          >Search</button>
        </span>
      </div>
    </div>
    <div
      v-if="suggestedLocations.length > 0"
      class="list-group geocode-results"
    >
      <button
        v-for="(suggestedLocation, index) in suggestedLocations"
        :key="suggestedLocation.label"
        type="button"
        class="list-group-item"
        :class="{ active: index == suggestedLocationIdx }"
        @click="selectRecommendedMarker(suggestedLocation, index)"
      >
        <span><img
          class="marker-container"
          src="/img/markers/photo_geocoded_blue.png"
          alt=""
        ></span><span
          class="item-description"
        >{{ suggestedLocation.label }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { Location } from '@aws-sdk/client-location'
import 'leaflet.markercluster'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity'
import { LatLng, LeafletMouseEvent, Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet'
import { makeGeocodedMarker, makeIconCreateFunction, makeNewPoiMarker, makeRegularMarker } from '@/utils/maps'
import { Poi, SimpleLatLng } from '@/types/domain';
import { Option } from '@/types/common';

interface Props {
  initialGuess?: Option<SimpleLatLng>
}

const props = defineProps<Props>()
const emit = defineEmits(['markerselected', 'locationchanged'])

const markerIcon = makeRegularMarker()
const geocodedMarkerIcon = makeGeocodedMarker()
const newMarkerIcon = makeNewPoiMarker()
const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!
const locationServiceIndexName = process.env.VUE_APP_AWS_LOCATION_SERVICE_INDEX_NAME!

const newMarker = ref<Option<Marker<any>>>()
const suggestedLocations = ref<any[]>([])
const suggestedLocationIdx = ref(-1)
const suggestedLocationMarkers = ref(new Map())
const locationClient = ref<Option<Location>>()
const location = ref<string>()
const map = ref<Option<LeafletMap>>()
const selectedExistingMarker = ref<Option<Marker<any>>>()
const initialGuessMarker = ref<Option<Marker<any>>>()
const selectedPoi = ref<Option<Poi>>()

const suggestedPoisMarkerClustererGroup = new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40,
  iconCreateFunction: makeIconCreateFunction('marker-cluster new-pois')
})
const existingPoisMarkerClustererGroup = new MarkerClusterGroup({
  showCoverageOnHover: false,
  maxClusterRadius: 40,
  iconCreateFunction: makeIconCreateFunction('marker-cluster')
})

const layerOptions = {
  tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  accessToken: process.env.VUE_APP_MAPBOX_API_KEY!,
  id: 'mapbox/light-v10',
}

const selectRecommendedMarker = async (location: any, idx: number) => {
  map.value!.setView(new LatLng(location.lat, location.lng))
  suggestedLocationIdx.value = idx
  suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))

  suggestedLocationMarkers.value.get(`${idx}`).setIcon(newMarkerIcon)
  if (newMarker.value) {
    map.value!.removeLayer(newMarker.value)
  }

  initialGuessMarker.value?.setIcon(markerIcon)
  emit('locationchanged', location)
}

const geocode = async () => {
  const geocodeApiResponse = await locationClient.value!.searchPlaceIndexForText({
    IndexName: locationServiceIndexName,
    Text: location.value
  })

  const deduplicationAccuracy = 4
  const deduplicatedResults = [
    ...new Map(geocodeApiResponse.Results!.map(res => [
      `${res?.Place?.Geometry?.Point![0].toFixed(deduplicationAccuracy)}_${res?.Place?.Geometry?.Point![1].toFixed(deduplicationAccuracy)}`,
      res
    ])).values()
  ]

  suggestedLocations.value = deduplicatedResults.map(res => ({
    lat: res?.Place?.Geometry?.Point![1],
    lng: res?.Place?.Geometry?.Point![0],
    label: res?.Place?.Label
  }))
}

watch(suggestedLocations, (newVal) => {
  map.value?.addLayer(suggestedPoisMarkerClustererGroup)
  suggestedLocationMarkers.value.clear()
  suggestedPoisMarkerClustererGroup.clearLayers()

  for (const locIdx in newVal) {
    const suggestLocation = suggestedLocations.value[locIdx]
    const marker = new Marker(
      new LatLng(suggestLocation.lat, suggestLocation.lng),
      {icon: geocodedMarkerIcon}
    )

    suggestedPoisMarkerClustererGroup.addLayer(marker)
    suggestedLocationMarkers.value.set(locIdx, marker)

    marker.on('click', () => {
      suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))
      suggestedLocationIdx.value = parseInt(locIdx)
      selectedPoi.value = undefined

      if (newMarker.value) {
        map.value?.removeLayer(newMarker.value)
      }

      marker.setIcon(newMarkerIcon)
      selectedExistingMarker.value?.setIcon(markerIcon)
      initialGuessMarker.value?.setIcon(markerIcon)

      emit('locationchanged', suggestLocation)
    })
  }

  map.value?.addLayer(suggestedPoisMarkerClustererGroup)
})

onMounted(async () => {
  locationClient.value = new Location({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentity({region}),
      identityPoolId: identityPoolId
    })
  })

  map.value = new LeafletMap('map-container', {zoomControl: false}).setView([48.137154, 11.576124], 6)
  map.value.doubleClickZoom.disable()

  const tileLayer = new TileLayer(
    layerOptions.tileLayerUrl,
    {
      attribution: '',
      maxZoom: 18,
      id: layerOptions.id,
      accessToken: layerOptions.accessToken,
    }
  )

  map.value.addLayer(tileLayer)
  map.value.addLayer(existingPoisMarkerClustererGroup)

  if (navigator.geolocation && !props.initialGuess) {
    navigator.geolocation.getCurrentPosition((position) => {
      map.value?.setView(new LatLng(position.coords.latitude, position.coords.longitude), 3)
    })
  }

  if (props.initialGuess) {
    const initialGuessGeoLocation = new LatLng(props.initialGuess.lat, props.initialGuess.lng)
    initialGuessMarker.value = new Marker(
      initialGuessGeoLocation,
      {icon: newMarkerIcon}
    )

    map.value.addLayer(initialGuessMarker.value)
    map.value.setView(initialGuessGeoLocation, 3)

    initialGuessMarker.value.on('click', () => {
      suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))
      suggestedLocationIdx.value = -1

      if (newMarker.value) {
        map.value?.removeLayer(newMarker.value)
      }

      selectedExistingMarker.value?.setIcon(markerIcon)
      initialGuessMarker.value?.setIcon(newMarkerIcon)

      emit('locationchanged', props.initialGuess)
    })
  }

  map.value.on('dblclick', (event: LeafletMouseEvent) => {
    selectedPoi.value = undefined
    suggestedLocationIdx.value = -1

    suggestedLocationMarkers.value.forEach((marker: Marker) => marker.setIcon(geocodedMarkerIcon))

    if (newMarker.value) {
      map.value?.removeLayer(newMarker.value)
    }
    newMarker.value = new Marker(event.latlng, {icon: newMarkerIcon})

    map.value?.addLayer(newMarker.value)

    selectedExistingMarker.value?.setIcon(markerIcon)
    initialGuessMarker.value?.setIcon(markerIcon)

    emit('locationchanged', event.latlng)
  })
})
</script>

<style scoped>
.map-container {
  padding-top: 5px;
  width: 100%;
  height: calc(((100vw / 4) - (2 * 15px - 1px)) * 3 / 4);
  border: black solid 2px;
}

.geocode-results {
  padding-top: 20px;
  text-align: left;
}

.geocode-results .item-description {
  padding-left: 20px;
}

.marker-container {
  height: 25px;
}
</style>

<style>
.new-pois div {
  background-color: rgb(138, 155, 191);
}

.searchbar {
  padding-top: 20px;
}

.marker-explanation-row {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap
}

.marker-explanation {
  font-size: xx-small;
  height: 15px;
}
</style>
